import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { Observable } from 'rxjs/internal/Observable';
import { Sklad } from '../models/sklad';
import { map } from 'rxjs';
import { Batch } from '../models/batches';

@Injectable({
  providedIn: 'root'
})
export class VyrobkySklademService {

  actionUrl: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService) {
    this.actionUrl = appSettings.config.actionUrl;
  }

  getSkladBrand(): Observable<Array<any>> {
    const skladBrandItems: Array<any> = [];
    const url = this.actionUrl + "/SQLQueries('sql07')/List";
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      items.value.forEach((element: { ItemCode: string; ItemName: string; NumInSale: number; OnHand: number;U_ABIACZ_VYRZKR: string;}) => {
        const skladBrand: Sklad = new Sklad();
        skladBrand.ItemCode = element.ItemCode;
        skladBrand.ItemName = element.ItemName;
        skladBrand.NumInSale = element.NumInSale;
        skladBrand.OnHand = element.OnHand;
        skladBrand.Carton = element.OnHand / element.NumInSale;
        skladBrand.U_ABIACZ_VYRZKR = element.U_ABIACZ_VYRZKR;
        skladBrandItems.push(skladBrand);
      });

      return skladBrandItems;
    }));
  }

  getSkladOwn(): Observable<Array<any>> {
    const skladOwnItems: Array<any> = [];
    const url = this.actionUrl + "/SQLQueries('sql08')/List";
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      items.value.forEach((element: { ItemCode: string; ItemName: string; NumInSale: number; OnHand: number;U_ABIACZ_VYRZKR: string;}) => {
        const skladOwn: Sklad = new Sklad();
        skladOwn.ItemCode = element.ItemCode;
        skladOwn.ItemName = element.ItemName;
        skladOwn.NumInSale = element.NumInSale;
        skladOwn.OnHand = element.OnHand;
        skladOwn.Carton = element.OnHand / element.NumInSale;
        skladOwn.U_ABIACZ_VYRZKR = element.U_ABIACZ_VYRZKR;
        skladOwnItems.push(skladOwn);
      });

      return skladOwnItems;
    }));
  }

  getBatches(itemCode: string) {
    const url = this.actionUrl + `/sml.svc/PRODUCTBATCHQTY?$select=BatchNum,Quantity,QtyBOX&$filter=ItemCode eq'${itemCode}'`;
    console.log(`Item code is ${itemCode}`);
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }

    return this.http.get<Batch[]>(url, options)
    .pipe(map(bachItems => {

      return bachItems;
    }))
  }
}
