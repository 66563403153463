import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { Smena } from 'src/app/models/smena';
import { VydejService } from 'src/app/services/vydej.service';
import { Category } from 'src/app/models/category';
import { Vydej } from 'src/app/models/vydej';
import { Action } from 'src/app/models/action';
import { UkonceniSmenyService } from 'src/app/services/ukonceni-smeny.service';
import { SegmentedButtonModule } from '@fundamental-ngx/core/segmented-button';
import { formatDate } from '@angular/common';
import { ValidatorsService } from 'src/app/services/validators.service';
import { DialogRef, DialogService } from '@fundamental-ngx/core';
import { EditaceSmenyService } from 'src/app/services/editace-smeny.service';
import { EditaceSmeny } from 'src/app/models/editaceSmeny';
import { ABIACZ_SDV1Collection } from 'src/app/models/doklad';

@Component({
  selector: 'app-smena',
  templateUrl: './smena.component.html',
  styleUrls: ['./smena.component.scss']
})
export class SmenaComponent implements OnInit, AfterViewInit {

  @ViewChild('confirmationDialog1') customTemplate1!: TemplateRef<any>;
  validatorModalDialog!: DialogRef<any, any>;

  yesterdays = '';
  todays = '';
  tomorrows = ''

  formatedDate = '';

  employeeID!: number;
  smenaID!: number;
  actionID!: number;
  docEntry!: number;
  public SM = Smena;
  public AC = Action;
  categoryItems: Category[] = [];
  tempVydej:Vydej = new Vydej();
  vydej:Vydej[] = [];
  status!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigation: NavigationService,
    private vydejService: VydejService,
    private ukonceniSmenyService: UkonceniSmenyService,
    private editaceSmenyService: EditaceSmenyService,
    private validatorsService: ValidatorsService,
    private dialog: DialogService,
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
        console.log(params);
        this.employeeID = Number(params.get('employeeID'));
        this.actionID = Number(params.get('actionID'));
        console.log('EmployeeID :', this.employeeID);
      }
    );
    
    // prepare date buttons
    this.yesterdays = this.setCurrentFormatedDate(-1, 'dd.MM.yyyy', 'en');
    this.todays = this.setCurrentFormatedDate(0, 'dd.MM.yyyy', 'en');
    this.tomorrows = this.setCurrentFormatedDate(1, 'dd.MM.yyyy', 'en');
    
    // this date will be propagated by default,  untill somebody click on date buttons
    this.setFormatedDate(0);

    // FAKE for testing - TO DELETE or at least comment !
    //localStorage.setItem('formatedDate', '2023-11-15');
  }

  ngAfterViewInit() {
    const menu = document.getElementById('menu-smena') as HTMLInputElement;
    switch(this.actionID) {
      case 0: // vydej materialu
        this.prepareCategories();
        break;
      case 1: // ukonceni smeny
      case 3: // editace smeny
        menu.style.display = 'block';
        break;
      case 2: // vyrobky skladem
        // pro vyrobky skladem se smena vubec nevola, ale vola se naprimo   
        console.error(`Vyrobky-skladem neopravnene pouzivaji komponentu Smena, actionId: ${this.actionID}`);
        break;
      default:
        console.error(`Nepovolena akce, actionId: ${this.actionID}`);
        break;
    }
  }

  nextAction(smena: Smena) {
    // validate first
    this.validatorsService.ukonceniSmeny(this.SM[smena]).subscribe({
      next: (validatorNumber: number) => {
        console.log(`Validator number: ${validatorNumber}`);
        if (validatorNumber === 0) {
          // vydej materialu nebo ukonceni smeny
          switch(this.AC[this.actionID]) {
            case "VYDEJMATERIALU":
              this.vydejMaterialu(smena);
              break;
            case "UKONCENISMENY":
              this.ukonceniSmeny(this.employeeID, smena);
              break;
            case "EDITACESMENY":
              this.editaceSmeny(smena);
              break;
          }
        } else {
          this.status = `Směna ukončena.`;

          this.validatorModalDialog = this.dialog.open(this.customTemplate1, {
            responsivePadding: true,
            ariaLabelledBy: 'table-one',
            focusTrapped: true
          });
      
          this.validatorModalDialog.afterClosed.subscribe(
            (result: string) => {
              console.log(result);
            },
            (error: string) => {
              console.error(error);
          });
        }
      },
      error: (err: any) => {
        console.error(err);
        this.status = err;

        this.validatorModalDialog = this.dialog.open(this.customTemplate1, {
          responsivePadding: true,
          ariaLabelledBy: 'table-one',
          focusTrapped: true
        });
    
        this.validatorModalDialog.afterClosed.subscribe(
          (result: string) => {
            console.log(result);
          },
          (error: string) => {
            console.error(error);
        });
      }
    })

    
  }
  
  vydejMaterialu(smena: Smena) {

    // Ulozeni smeny do cookie
    localStorage.setItem('smena', smena.toString());

    // prevod na ID smeny
    this.smenaID = Number(smena);

    // Kontrola existence dokladu - ověřit jestli existuje doklad na uživatele a směnu
    this.vydejService.kontrolaExistenceDokladu(this.employeeID, this.SM[this.smenaID]).subscribe({
      next: (exists: boolean) => {
        console.log('Kontrola excistence dokladu :', exists);

        // Pokud existuje doklad, pak nacteme data do tabulky
        if (exists) {
          this.nacteniDokladu(this.employeeID);
        } else {
          // Call vydej component
          this.router.navigate(['/vydej'], 
          { queryParams: { employeeID: this.employeeID, smena: smena, docEntry: this.docEntry } });
        }
      },
      error: (err: any) => {
        console.error(err);
      }, 
      complete() {
        // Will not be called, even when cancelling subscription.
        console.log('completed!');
        
      }
    })
  }

  nacteniDokladu(employeeID: number) {
    this.vydejService.nacteniDokladu(employeeID, this.SM[this.smenaID]).subscribe({
      next: (doklad: any) => {
        console.log('Doklad :', doklad);

        doklad.value[0].ABIACZ_SDV1Collection.forEach((element: any) => {
          console.log(element);
          this.tempVydej.lineId = element.LineId;
          this.tempVydej.visOrder = element.VisOrder;
          this.tempVydej.object = element.Object;
          this.tempVydej.itemCode = element.U_ABIACZ_ITEMCODE;
          this.tempVydej.itemName = element.U_ABIACZ_ITEMNAME;
          this.tempVydej.batchNum = element.U_ABIACZ_BATCH;
          this.tempVydej.quantity = element.U_ABIACZ_QTY;
          this.tempVydej.mnozstvi = element.U_ABIACZ_QTY;
          this.tempVydej.spotrebaOd = element.U_ABIACZ_FROM;
          this.tempVydej.spotrebaDo = element.U_ABIACZ_TO;
          this.tempVydej.vadneKusy = element.U_ABIACZ_SCRAP;
          this.tempVydej.docEntry = element.DocEntry;
          this.docEntry = element.DocEntry;
          this.vydej.push(this.tempVydej);
          this.tempVydej = new Vydej();
        });

        localStorage.setItem('docEntry', JSON.stringify(this.docEntry));
        localStorage.setItem('doklad', JSON.stringify(this.vydej));

        // Call vydej component
        this.router.navigate(['/vydej'], 
        { queryParams: { employeeID: this.employeeID, smena: this.SM[this.smenaID], docEntry: this.docEntry }});
      },
      error: (err: any) => {
        console.error(err);
      }
    })
  }

  prepareCategories() {
    this.vydejService.getCategories().subscribe({
        next: async (categories: Category[]) => {
          console.log(categories);
          let i = 1; 
          for await (const category of categories) {
             this.vydejService.getItemsInCategory(category.code, category.name).subscribe({
                next: (item: any) => {
                    console.log(item);
                    this.categoryItems.push(item);
                    
                    this.categoryItems.sort(function(x,y) {
                      if (x.name < y.name) return -1;
                      if (x.name > y.name) return 1;
                      return 0;
                    })
                    
                    localStorage.setItem('categoryItems', JSON.stringify(this.categoryItems));
                },
                error: (err: any) => {
                    console.error(err);
                },
                complete() {
                  // Will not be called, even when cancelling subscription.
                  console.log('completed!');
                  i++;
                  if (i > categories.length) {
                    const menu = document.getElementById('menu-smena') as HTMLInputElement;
                    menu.style.display = 'block';
                  }
                }
            });
          }
          // alert('Data jsou nactena.');
        },
        error: (err: any) => {
          console.error(err);
        }, 
    });
  }

  ukonceniSmeny(employeeID: number, smena: Smena) {
    // Ulozeni smeny do cookie
    localStorage.setItem('smena', smena.toString());

    // prevod na ID smeny
    this.smenaID = Number(smena);

    // nacteni dokladu a ulozeni DocEntry do cookie
    this.vydejService.nacteniDokladu(employeeID, this.SM[this.smenaID]).subscribe({
      next: (doklad: any) => {
        console.log('Doklad :', doklad);
        doklad?.value[0]?.ABIACZ_SDV1Collection.forEach((element: any) => {
          console.log(element);
          this.docEntry = element.DocEntry;
        });

        localStorage.setItem('docEntry', JSON.stringify(this.docEntry));
      },
      error: (err: any) => {
        console.error(err);
      }
    });

    // nacteni seznamu expedice a ulozeni do cookie
    this.ukonceniSmenyService.seznamExpedice(this.SM[this.smenaID]).subscribe({
      next: (expedice: any) => {
        localStorage.setItem('seznamExpedice', JSON.stringify(expedice));
      },
      error: (err: any) => {
        console.error(err);
      },
      complete() {
        // Will not be called, even when cancelling subscription.
        console.log('completed!');
      }
    });

     // nacteni seznamu pro vyrobu a ulozeni do cookie
     this.ukonceniSmenyService.seznamVyroba(this.SM[this.smenaID]).subscribe({
      next: (vyroba: any) => {
        localStorage.setItem('seznamVyroba', JSON.stringify(vyroba));
      },
      error: (err: any) => {
        console.error(err);
      },
      complete() {
        // Will not be called, even when cancelling subscription.
        console.log('completed!');
      }
    });

    // seznam pro vydej
    this.ukonceniSmenyService.seznamProVydej(this.SM[this.smenaID]).subscribe({
      next: (seznamVydejuNaSmenu: any) => {
        
        localStorage.setItem('docEntry', JSON.stringify(this.docEntry));
        localStorage.setItem('seznamProVydej', JSON.stringify(seznamVydejuNaSmenu));

        // Call ukonceni smeny component
        this.router.navigate(['/konec'], 
        { queryParams: { employeeID: this.employeeID, smena: this.SM[this.smenaID], docEntry: this.docEntry } });
      },
      error: (err: any) => {
        console.error(err);
      },
      complete() {
        // Will not be called, even when cancelling subscription.
        console.log('completed!');
      }
    });
  }

  editaceSmeny(smena: Smena) {
    // Ulozeni smeny do cookie
    localStorage.setItem('smena', smena.toString());

    // prevod na ID smeny
    this.smenaID = Number(smena);

    // nacteni seznamu editace smeny a ulozeni do cookie
    this.editaceSmenyService.editaceSmeny(this.SM[this.smenaID]).subscribe({
        next: (seznamEditaceSmeny: Array<EditaceSmeny>) => {
          console.log('Editace smeny :', seznamEditaceSmeny.length);
          localStorage.setItem('seznamEditaceSmeny', JSON.stringify(seznamEditaceSmeny));

          this.docEntry = seznamEditaceSmeny[0]?.DocEntry;
          localStorage.setItem('docEntry', JSON.stringify(this.docEntry));
          if (this.docEntry !== undefined) {
            this.editaceSmenyService.nacteniDokladu(this.docEntry).subscribe({
              next: (sDV1CollectionList: Array<ABIACZ_SDV1Collection>) => {
                console.log('Polozky dokladu :', sDV1CollectionList.length);
                localStorage.setItem('editacePolozkyDokladu', JSON.stringify(sDV1CollectionList));
              }
            });
          }
          
          // Call ukonceni smeny component
          this.router.navigate(['/editace-smeny'], 
          { queryParams: { employeeID: this.employeeID, smena: this.SM[this.smenaID] } });
          },
        error: (err: any) => {
          console.error(err);
        }
      }
    )

    
  }

  back(): void {
    this.navigation.back();
  }

  end() {
    console.log('Konec');
    this.navigation.konec();
  }

  setCurrentFormatedDate(offset: number, format: string, locale: string): string {
    const currentDate = new Date(new Date().setDate(new Date().getDate() + offset));
    const formatedDate = formatDate(currentDate, format, locale);
    return formatedDate;
  }

  setFormatedDate(offset: number) {
    this.formatedDate = this.setCurrentFormatedDate(offset, 'yyyy-MM-dd', 'en');

    // Ulozeni aktualniho datumu do cookie
    localStorage.setItem('formatedDate', this.formatedDate);
  }
}
