import { ColorAccent, ShellbarUser } from "@fundamental-ngx/core";

export class User implements ShellbarUser {
    id?: number;
    sessionId!: string;
    version!: number;
    sessionTimeout!: number;
    username!: string;
    password!: string;
    firstName!: string;
    lastName!: string;
    authdata?: string;
    fullName?: string;
    colorAccent?: ColorAccent;
}