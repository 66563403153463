<div id="vyrobky-skladem-left-table">
    <table fd-table>
        <thead fd-table-header>
            <tr fd-table-row>
                <th fd-table-cell>Artikl</th>
                <th fd-table-cell>Zkratka</th>
                <th fd-table-cell>Kartonů</th>
                <th fd-table-cell>Množství</th>
            </tr>
        </thead>
        <tbody fd-table-body>
            <tr fd-table-row [focusable]="true" *ngFor="let item of skladBrand" (click)="action(item.ItemCode)">
                <td fd-table-cell>{{ item.ItemName }}</td>
                <td fd-table-cell>{{ item.U_ABIACZ_VYRZKR }}</td>
                <td fd-table-cell class="td-text-right">{{ item.OnHand/item.NumInSale | number : '1.2-2' }}</td>
                <td fd-table-cell class="td-text-right">{{ item.OnHand | number : '1.2-2' }}</td>
            </tr>
        </tbody>
    </table>
</div>

<div id="vyrobky-skladem-right-table">
    <table fd-table>
        <thead fd-table-header>
            <tr fd-table-row>
                <th fd-table-cell>Artikl</th>
                <th fd-table-cell>Zkratka</th>
                <th fd-table-cell>Kartonů</th>
                <th fd-table-cell>Množství</th>
            </tr>
        </thead>
        <tbody fd-table-body>
            <tr fd-table-row [focusable]="true" *ngFor="let item of skladOwn" (click)="action(item.ItemCode)">
                <td fd-table-cell>{{ item.ItemName }}</td>
                <td fd-table-cell>{{ item.U_ABIACZ_VYRZKR }}</td>
                <td fd-table-cell class="td-text-right">{{ item.OnHand/item.NumInSale | number : '1.2-2' }}</td>
                <td fd-table-cell class="td-text-right">{{ item.OnHand | number : '1.2-2' }}</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="modal">
    <ng-template let-dialog let-dialogConfig="dialogConfig" #confirmationDialog1>
        <fd-dialog [dialogConfig]="dialogConfig" [dialogRef]="dialog">
            <fd-dialog-body>
                <table fd-table aria-label="table-one">
                    <thead fd-table-header>
                        <tr fd-table-row>
                            <th fd-table-cell>Šarže</th>
                            <th fd-table-cell>Kartony</th>
                            <th fd-table-cell>Množství</th>
                        </tr>
                    </thead>
                    <tbody fd-table-body>
                        <tr
                            *ngFor="let row of batchesRows" fd-table-row
                            [ngClass]="row.active === true ? 'active': 'greyedOut'"
                            >
                            <td fd-table-cell>
                                <p fd-table-text>
                                    {{ row.batchNum }}</p>
                            </td>
                            <td fd-table-cell>
                                <p fd-table-text >
                                    {{ row.qtyBOX | number : '1.2-2' }}
                                </p>
                            </td>
                            <td fd-table-cell>
                              <p fd-table-text >
                                  {{ row.quantity }}
                              </p>
                          </td>
                        </tr>
                    </tbody>
                </table>
            </fd-dialog-body>

            <fd-dialog-footer>
                <fd-button-bar label="Zrušit" (click)="dialog.dismiss('Cancel')" ariaLabel="Cancel"></fd-button-bar>
            </fd-dialog-footer>
        </fd-dialog>
    </ng-template>
</div>

<div class="footer">
    <input type="button" value="Zpět" (click)="back()" aria-label="Back" id="back" class="back"/>
    <input type="button" value="Konec" (click)="end()" ariaLabel="End" id="end" class="end" />
</div>
