import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authenticationService.userValue;
      if ((user) && (Number(localStorage.getItem('timeout')) >= Date.now()))  {
          // logged in so return true
          return true;
      }

      // not logged in so redirect to login page with the return url
      this.authenticationService.logout();
      this.router.navigate(['/signin'], { queryParams: { returnUrl: state.url }, replaceUrl: true });
      return false;
  }
  
}
