import { Batch } from "./batches";

export class Vydejka {
    DocDate!: string;
    DocDueDate!: string;
    U_ABIAG_OIGE!: string;
    U_ABIACZ_VODA!: number;
    U_ABIACZ_PLYN!: number;
    U_ABIACZ_ODPAD!: number;
    U_ABIACZ_VSTART!: string;
    U_ABIACZ_VKONEC!: string;
    U_ABIACZ_VPREST!: string;
    U_ABIACZ_SHIFT!: string;
    //DocObjectCode!: string;
    DocumentLines!: DocumentLine[];
}

export class DocumentLine {
    ItemCode!: string;
    Quantity!: number;
    WarehouseCode!: string;
    AccountCode!: string;
    BatchNumbers!: BatchNumber[];
}

export class BatchNumber {
    BatchNumber!: string;
    Quantity!: number;
}

