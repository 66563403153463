<div id="menu-funkce">
    <button (click)="vydejMaterialu(AC.VYDEJMATERIALU)" *ngIf="roleID === 1 || roleID === 3 || roleID === 4">
        Výdej materiálu
    </button>

    <button (click)="ukonceniSmeny(AC.UKONCENISMENY)" *ngIf="roleID === 1 || roleID === 3">
        Ukončení směny
    </button>

    <button (click)="vyrobkySkladem(AC.VYROBKYSKLADEM)">
        Výrobky skladem
    </button>

    <button (click)="editaceSmeny(AC.EDITACESMENY)" *ngIf="roleID === 1 || roleID === 3">
        Editace směny
    </button>
</div>

<div class="footer">
    <input type="button" value="Zpět" (click)="back()" aria-label="Back" id="back" class="back"/>
    <input type="button" value="Konec" (click)="end()" ariaLabel="End" id="end" class="end" />
</div>
