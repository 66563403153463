<div class="breadcrumb">
    Editace směny - {{breadcrumbFormatedDate}} - {{breadCrumbSmena}}
</div>

<div id="editace-smeny">
    <table>
        <thead fd-table-header>
            <tr fd-table-row>
              <th fd-table-cell>Artikl</th>
              <th fd-table-cell>Šarže</th>
              <th fd-table-cell>Množství</th>
              <th fd-table-cell>Poškozeno</th>
              <th fd-table-cell>Od</th>
              <th fd-table-cell>Do</th>
              <th fd-table-cell>Datum</th>
              <th fd-table-cell>Čas zadání</th>
              <th fd-table-cell>Jméno</th>
              <th fd-table-cell>Příjmení</th>
              <th fd-table-cell></th>
            </tr>
        </thead>
        <tbody fd-table-body>
            <tr fd-table-row [focusable]="true" *ngFor="let item of seznamEditaceSmeny">
              <td fd-table-cell>{{ item.U_ABIACZ_ITEMNAME }}</td>
                <td fd-table-cell>{{ item.U_ABIACZ_BATCH }}</td>
                <td fd-table-cell>{{ item.U_ABIACZ_QTY }}</td>
                <td fd-table-cell>{{ item.U_ABIACZ_SCRAP }}</td>
                <td fd-table-cell>{{ item.U_ABIACZ_FROM }}</td>
                <td fd-table-cell>{{ item.U_ABIACZ_TO }}</td>
                <td fd-table-cell>{{ item.U_ABIACZ_DATE }}</td>
                <td fd-table-cell>{{ item.U_ABIACZ_TIME }}</td>
                <td fd-table-cell>{{ item.firstName }}</td>
                <td fd-table-cell>{{ item.lastName }}</td>
                <td fd-table-cell>
                    <button fd-button glyph="delete" (click)="remove(item)"></button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="footer">
    <input type="button" value="Zpět" (click)="back()" aria-label="Back" id="back" class="back"/>
    <input type="button" value="Konec" (click)="end()" ariaLabel="End" id="end" class="end" />
</div>
