export class Doklad {
    docNum!: number;
    period!: number;
    instance!: number;
    series!: number;
    handwrtten!: string;
    status!: string;
    requestStatus!: string;
    creator!: string;
    remark!: string;
    docEntry!: number;
    canceled!: string;
    object!: string;
    logInst!: null;
    userSign!: number;
    transfered!: string;
    createDate!: string;
    createTime!: string;
    updateDate!: string;
    updateTime!: string;
    dataSource!: string;
    U_ABIACZ_USER!: string;
    U_ABIACZ_SHIFT!: string;
    U_ABIACZ_PC!: string;
    U_ABIACZ_Status!: string;
    U_ABIACZ_DOCDATE!: string;
    ABIACZ_SDV1Collection!: ABIACZ_SDV1Collection[];
}

export class ABIACZ_SDV1Collection {
    DocEntry!: number;
    LineId!: number;
    VisOrder!: number;
    Object!: string;
    LogInst!: null;
    U_ABIACZ_ITEMCODE!: string;
    U_ABIACZ_ITEMNAME!: string;
    U_ABIACZ_QTY!: number;
    U_ABIACZ_BATCH!: string;
    U_ABIACZ_DATE!: string;
    U_ABIACZ_TIME!: string;
    U_ABIACZ_SCRAP!: number;
    U_ABIACZ_FROM!: string;
    U_ABIACZ_TO!: string;
}