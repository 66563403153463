import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from '@fundamental-ngx/core';
import { Batch, Batches } from 'src/app/models/batches';
import { Sklad } from 'src/app/models/sklad';
import { NavigationService } from 'src/app/services/navigation.service';
import { VyrobkySklademService } from 'src/app/services/vyrobky-skladem.service';

@Component({
  selector: 'app-vyrobky-skladem',
  templateUrl: './vyrobky-skladem.component.html',
  styleUrls: ['./vyrobky-skladem.component.scss']
})
export class VyrobkySklademComponent implements OnInit {
  @ViewChild('confirmationDialog1') customTemplate1!: TemplateRef<any>;
  skladBrand: Array<Sklad> = [];
  skladOwn: Array<Sklad> = [];
  batchesRows!: Batch[];
  
  constructor (
    private navigation: NavigationService, 
    private dialog: DialogService,
    private vyrobkySklademService: VyrobkySklademService)
  { }

  ngOnInit(): void {
    // Nacteni brand vyrobku skladem z cookie
    const skladBrandItems = localStorage.getItem('skladBrandItems');
    this.skladBrand = JSON.parse(skladBrandItems ?? '');
    console.log(this.skladBrand);

    // Nacteni own vyrobku skladem z cookie
    const skladOwnItems = localStorage.getItem('skladOwnItems');
    this.skladOwn = JSON.parse(skladOwnItems ?? '');
    console.log(this.skladOwn);
  }

  action(itemCode: string){
    console.log(`Item code: ${itemCode}`);

    // open modal witch batches
    const dialogRef = this.dialog.open(this.customTemplate1, {
      responsivePadding: true,
      ariaLabelledBy: 'table-one',
      focusTrapped: true
    });

    dialogRef.afterClosed.subscribe(
        (result: string) => {
            console.log('Dialog closed with result: ' + result);    
        },
        (error: string) => {
            console.log(error);
        }
    );

    
    this.vyrobkySklademService.getBatches(itemCode).subscribe({
      next: (val: any) => {
        console.log(val.value);
        const batches: Batches = new Batches();
          batches.items = [];
          val.value.forEach((row: { BatchNum: string; Quantity: string; QtyBOX: string; }) => {
            const batch: Batch = new Batch();
            batch.batchNum = row.BatchNum;
            batch.quantity = Number(row.Quantity);
            batch.qtyBOX = Number(row.QtyBOX);
            batch.active = false;

            batches.items.push(batch);
          });

          this.batchesRows = batches.items;
      },
      error: (err: any) => {
        console.error(err);
      }
    });
    
  }
  
  back(): void {
    this.navigation.back();
  }

  end() {
    console.log('Konec');
    this.navigation.konec();
  }

}
