export enum NumpadType {
    PIN,
    MNOZSTVI,
    SPOTREBAOD,
    SPOTREBADO,
    VADNEKUSY,
    SPOTREBAVODY,
    SPOTREBAPLYNU,
    ODPAD,
    OLEJ,
    ZACATEKVYROBY,
    KONECVYROBY,
    DOBAPRESTAVKY,
    DISABLED
  }