<ng-template let-dialog let-dialogConfig="dialogConfig" #confirmationDialog>
    <fd-dialog [dialogConfig]="dialogConfig" [dialogRef]="dialog">
        <fd-dialog-header>
            <h1 id="fd-dialog-header-10" fd-title>{{title}}</h1>
        </fd-dialog-header>

        <fd-dialog-body>
            <div class="row" *ngIf="type === 0">
                <input fd-form-control type="string" [value]=pin id="pin" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="managePin('7')"></button>
                    <button fd-button label="8" (click)="managePin('8')"></button>
                    <button fd-button label="9" (click)="managePin('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="managePin('4')"></button>
                    <button fd-button label="5" (click)="managePin('5')"></button>
                    <button fd-button label="6" (click)="managePin('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="managePin('1')"></button>
                    <button fd-button label="2" (click)="managePin('2')"></button>
                    <button fd-button label="3" (click)="managePin('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="managePin('-1')"></button>
                    <button fd-button label="0" (click)="managePin('0')"></button>
                    <button fd-button label="C" (click)="managePin('C')"></button>
                </div>
            </div>
            <div class="row" *ngIf="type === 1">
                <input fd-form-control type="string" [value]=mnozstvi id="mnozstvi" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageMnozstvi('7')"></button>
                    <button fd-button label="8" (click)="manageMnozstvi('8')"></button>
                    <button fd-button label="9" (click)="manageMnozstvi('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageMnozstvi('4')"></button>
                    <button fd-button label="5" (click)="manageMnozstvi('5')"></button>
                    <button fd-button label="6" (click)="manageMnozstvi('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageMnozstvi('1')"></button>
                    <button fd-button label="2" (click)="manageMnozstvi('2')"></button>
                    <button fd-button label="3" (click)="manageMnozstvi('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageMnozstvi('-1')"></button>
                    <button fd-button label="0" (click)="manageMnozstvi('0')"></button>
                    <button fd-button label="C" (click)="manageMnozstvi('C')"></button>
                    <button fd-button label="." (click)="manageMnozstvi('.')"></button>
                </div>
            </div>
            <div class="row" *ngIf="type === 4">
                <input fd-form-control type="string" [value]=vadneKusy id="vadneKusy" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageVadneKusy('7')"></button>
                    <button fd-button label="8" (click)="manageVadneKusy('8')"></button>
                    <button fd-button label="9" (click)="manageVadneKusy('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageVadneKusy('4')"></button>
                    <button fd-button label="5" (click)="manageVadneKusy('5')"></button>
                    <button fd-button label="6" (click)="manageVadneKusy('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageVadneKusy('1')"></button>
                    <button fd-button label="2" (click)="manageVadneKusy('2')"></button>
                    <button fd-button label="3" (click)="manageVadneKusy('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageVadneKusy('-1')"></button>
                    <button fd-button label="0" (click)="manageVadneKusy('0')"></button>
                    <button fd-button label="C" (click)="manageVadneKusy('C')"></button>
                    <button fd-button label="." (click)="manageVadneKusy('.')"></button>
                    
                </div>
            </div>
            <div class="row" *ngIf="type === 2">
                <input fd-form-control type="string" [value]=spotrebaOd id="spotrebaOd" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageSpotrebaOd('7')"></button>
                    <button fd-button label="8" (click)="manageSpotrebaOd('8')"></button>
                    <button fd-button label="9" (click)="manageSpotrebaOd('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageSpotrebaOd('4')"></button>
                    <button fd-button label="5" (click)="manageSpotrebaOd('5')"></button>
                    <button fd-button label="6" (click)="manageSpotrebaOd('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageSpotrebaOd('1')"></button>
                    <button fd-button label="2" (click)="manageSpotrebaOd('2')"></button>
                    <button fd-button label="3" (click)="manageSpotrebaOd('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageSpotrebaOd('-1')"></button>
                    <button fd-button label="0" (click)="manageSpotrebaOd('0')"></button>
                    <button fd-button label="C" (click)="manageSpotrebaOd('C')"></button>
                    <button fd-button label=":" (click)="manageSpotrebaOd(':')"></button>
                </div>
            </div>
            <div class="row" *ngIf="type === 3">
                <input fd-form-control type="string" [value]=spotrebaDo id="spotrebaDo" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageSpotrebaDo('7')"></button>
                    <button fd-button label="8" (click)="manageSpotrebaDo('8')"></button>
                    <button fd-button label="9" (click)="manageSpotrebaDo('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageSpotrebaDo('4')"></button>
                    <button fd-button label="5" (click)="manageSpotrebaDo('5')"></button>
                    <button fd-button label="6" (click)="manageSpotrebaDo('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageSpotrebaDo('1')"></button>
                    <button fd-button label="2" (click)="manageSpotrebaDo('2')"></button>
                    <button fd-button label="3" (click)="manageSpotrebaDo('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageSpotrebaDo('-1')"></button>
                    <button fd-button label="0" (click)="manageSpotrebaDo('0')"></button>
                    <button fd-button label="C" (click)="manageSpotrebaDo('C')"></button>
                    <button fd-button label=":" (click)="manageSpotrebaDo(':')"></button>
                </div>
            </div>
            
            <div class=""row *ngIf="type === 5">
                <input fd-form-control type="string" [value]=spotrebaVody id="spotrebaVody" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageSpotrebaVody('7')"></button>
                    <button fd-button label="8" (click)="manageSpotrebaVody('8')"></button>
                    <button fd-button label="9" (click)="manageSpotrebaVody('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageSpotrebaVody('4')"></button>
                    <button fd-button label="5" (click)="manageSpotrebaVody('5')"></button>
                    <button fd-button label="6" (click)="manageSpotrebaVody('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageSpotrebaVody('1')"></button>
                    <button fd-button label="2" (click)="manageSpotrebaVody('2')"></button>
                    <button fd-button label="3" (click)="manageSpotrebaVody('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageSpotrebaVody('-1')"></button>
                    <button fd-button label="0" (click)="manageSpotrebaVody('0')"></button>
                    <button fd-button label="C" (click)="manageSpotrebaVody('C')"></button>
                    <button fd-button label="." (click)="manageSpotrebaVody('.')"></button>
                </div>
            </div>
            <div class=""row *ngIf="type === 6">
                <input fd-form-control type="string" [value]=spotrebaPlynu id="spotrebaPlynu" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageSpotrebaPlynu('7')"></button>
                    <button fd-button label="8" (click)="manageSpotrebaPlynu('8')"></button>
                    <button fd-button label="9" (click)="manageSpotrebaPlynu('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageSpotrebaPlynu('4')"></button>
                    <button fd-button label="5" (click)="manageSpotrebaPlynu('5')"></button>
                    <button fd-button label="6" (click)="manageSpotrebaPlynu('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageSpotrebaPlynu('1')"></button>
                    <button fd-button label="2" (click)="manageSpotrebaPlynu('2')"></button>
                    <button fd-button label="3" (click)="manageSpotrebaPlynu('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageSpotrebaPlynu('-1')"></button>
                    <button fd-button label="0" (click)="manageSpotrebaPlynu('0')"></button>
                    <button fd-button label="C" (click)="manageSpotrebaPlynu('C')"></button>
                    <button fd-button label="." (click)="manageSpotrebaPlynu('.')"></button>
                </div>
            </div>
            <div class=""row *ngIf="type === 7">
                <input fd-form-control type="string" [value]=odpad id="odpad" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageOdpad('7')"></button>
                    <button fd-button label="8" (click)="manageOdpad('8')"></button>
                    <button fd-button label="9" (click)="manageOdpad('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageOdpad('4')"></button>
                    <button fd-button label="5" (click)="manageOdpad('5')"></button>
                    <button fd-button label="6" (click)="manageOdpad('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageOdpad('1')"></button>
                    <button fd-button label="2" (click)="manageOdpad('2')"></button>
                    <button fd-button label="3" (click)="manageOdpad('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageOdpad('-1')"></button>
                    <button fd-button label="0" (click)="manageOdpad('0')"></button>
                    <button fd-button label="C" (click)="manageOdpad('C')"></button>
                    <button fd-button label="." (click)="manageOdpad('.')"></button>
                </div>
            </div>
            <div class=""row *ngIf="type === 8">
                <input fd-form-control type="string" [value]=olej id="olej" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageOlej('7')"></button>
                    <button fd-button label="8" (click)="manageOlej('8')"></button>
                    <button fd-button label="9" (click)="manageOlej('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageOlej('4')"></button>
                    <button fd-button label="5" (click)="manageOlej('5')"></button>
                    <button fd-button label="6" (click)="manageOlej('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageOlej('1')"></button>
                    <button fd-button label="2" (click)="manageOlej('2')"></button>
                    <button fd-button label="3" (click)="manageOlej('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageOlej('-1')"></button>
                    <button fd-button label="0" (click)="manageOlej('0')"></button>
                    <button fd-button label="C" (click)="manageOlej('C')"></button>
                    <button fd-button label="." (click)="manageOlej('.')"></button>
                </div>
            </div>

            <div class="row" *ngIf="type === 9">
                <input fd-form-control type="string" [value]=zacatekVyroby id="zacatekVyroby" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageZacatekVyroby('7')"></button>
                    <button fd-button label="8" (click)="manageZacatekVyroby('8')"></button>
                    <button fd-button label="9" (click)="manageZacatekVyroby('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageZacatekVyroby('4')"></button>
                    <button fd-button label="5" (click)="manageZacatekVyroby('5')"></button>
                    <button fd-button label="6" (click)="manageZacatekVyroby('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageZacatekVyroby('1')"></button>
                    <button fd-button label="2" (click)="manageZacatekVyroby('2')"></button>
                    <button fd-button label="3" (click)="manageZacatekVyroby('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageZacatekVyroby('-1')"></button>
                    <button fd-button label="0" (click)="manageZacatekVyroby('0')"></button>
                    <button fd-button label="C" (click)="manageZacatekVyroby('C')"></button>
                    <button fd-button label=":" (click)="manageZacatekVyroby(':')"></button>
                </div>
            </div>
            <div class="row" *ngIf="type === 10">
                <input fd-form-control type="string" [value]=konecVyroby id="konecVyroby" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageKonecVyroby('7')"></button>
                    <button fd-button label="8" (click)="manageKonecVyroby('8')"></button>
                    <button fd-button label="9" (click)="manageKonecVyroby('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageKonecVyroby('4')"></button>
                    <button fd-button label="5" (click)="manageKonecVyroby('5')"></button>
                    <button fd-button label="6" (click)="manageKonecVyroby('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageKonecVyroby('1')"></button>
                    <button fd-button label="2" (click)="manageKonecVyroby('2')"></button>
                    <button fd-button label="3" (click)="manageKonecVyroby('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageKonecVyroby('-1')"></button>
                    <button fd-button label="0" (click)="manageKonecVyroby('0')"></button>
                    <button fd-button label="C" (click)="manageKonecVyroby('C')"></button>
                    <button fd-button label=":" (click)="manageKonecVyroby(':')"></button>
                </div>
            </div>
            <div class="row" *ngIf="type === 11">
                <input fd-form-control type="string" [value]=dobaPrestavky id="dobaPrestavky" placeholder={{placeholder}} readonly />
                <div class="row">
                    <button fd-button label="7" (click)="manageDobaPrestavky('7')"></button>
                    <button fd-button label="8" (click)="manageDobaPrestavky('8')"></button>
                    <button fd-button label="9" (click)="manageDobaPrestavky('9')"></button>
                </div>
                <div class="row">
                    <button fd-button label="4" (click)="manageDobaPrestavky('4')"></button>
                    <button fd-button label="5" (click)="manageDobaPrestavky('5')"></button>
                    <button fd-button label="6" (click)="manageDobaPrestavky('6')"></button>
                </div>
                <div class="row">
                    <button fd-button label="1" (click)="manageDobaPrestavky('1')"></button>
                    <button fd-button label="2" (click)="manageDobaPrestavky('2')"></button>
                    <button fd-button label="3" (click)="manageDobaPrestavky('3')"></button>
                </div>
                <div class="row">
                    <button fd-button label="<-" (click)="manageDobaPrestavky('-1')"></button>
                    <button fd-button label="0" (click)="manageDobaPrestavky('0')"></button>
                    <button fd-button label="C" (click)="manageDobaPrestavky('C')"></button>
                    <button fd-button label=":" (click)="manageDobaPrestavky(':')"></button>
                </div>
            </div>

            <div class=""row *ngIf="type === 12">
                <p>DISABLED</p>
            </div>
             
        </fd-dialog-body>

        <fd-dialog-footer>
            <p>{{status}}</p>
            <button fd-button label="Ok" (click)="checkPin()" ariaLabel="Ok" *ngIf="okEnabled" ></button>
        </fd-dialog-footer>
    </fd-dialog>
</ng-template>

<!-- <input *ngIf="type === 0" fd-form-control type="string" [value]=pin id="pinModal" placeholder={{placeholder}} readonly (click)="openDialog()" /> -->
<input *ngIf="type === 1" fd-form-control type="string" [value]=mnozstvi id="mnozstviModal" placeholder={{placeholder}} readonly (click)="openDialog()" />
<input *ngIf="type === 4" fd-form-control type="string" [value]=vadneKusy id="vadneKusyModal" placeholder={{placeholder}} readonly (click)="openDialog()" />
<input *ngIf="type === 2" fd-form-control type="string" [value]=spotrebaOd id="spotrebaOdModal" placeholder={{placeholder}} readonly (click)="openDialog();" />
<input *ngIf="type === 3" fd-form-control type="string" [value]=spotrebaDo id="spotrebaDoModal" placeholder={{placeholder}} readonly (click)="openDialog();" />

<input *ngIf="type === 5" fd-form-control type="string" [value]=spotrebaVody id="spotrebaVodyModal" placeholder={{placeholder}} readonly (click)="openDialog()" />
<input *ngIf="type === 6" fd-form-control type="string" [value]=spotrebaPlynu id="spotrebaPlynuModal" placeholder={{placeholder}} readonly (click)="openDialog()" />
<input *ngIf="type === 7" fd-form-control type="string" [value]=odpad id="odpadModal" placeholder={{placeholder}} readonly (click)="openDialog()" />
<input *ngIf="type === 8" fd-form-control type="string" [value]=olej id="olejModal" placeholder={{placeholder}} readonly (click)="openDialog()" />

<input *ngIf="type === 9" fd-form-control type="string" [value]=zacatekVyroby id="zacatekVyrobyModal" placeholder={{placeholder}} readonly (click)="openDialog();" />
<input *ngIf="type === 10" fd-form-control type="string" [value]=konecVyroby id="konecVyrobyModal" placeholder={{placeholder}} readonly (click)="openDialog();" />
<input *ngIf="type === 11" fd-form-control type="string" [value]=dobaPrestavky id="dobaPrestavkyModal" placeholder={{placeholder}} readonly (click)="openDialog()" />

<input *ngIf="type === 12" fd-form-control type="string" [value]=mnozstvi id="disabledMnozstviModal" placeholder={{placeholder}} readonly disabled (click)="openDialog()" />