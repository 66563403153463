export class Vydej {
    lineId!: number;
    visOrder!: number;
    object!: string;
    categoryCode!: string;
    itemName!: string;
    itemCode!: string;
    mnozstvi!: string;
    spotrebaOd!: string;
    spotrebaDo!: string;
    vadneKusy!: string;
    batchNum!: string;
    quantity!: number;
    docEntry!: number; // id dokladu
    manageBatchNumbers!: string;
    quantityOnStock!: number;
}