import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { Observable, catchError, map, of } from 'rxjs';
import { Category, CategoryItem } from '../models/category';
import { Batch } from '../models/batches';
import { AuthenticationService } from './authentication.service';
import { Smena } from '../models/smena';
import { formatDate } from '@angular/common';
import { ABIACZ_SDV1Collection, Doklad } from '../models/doklad';
import { Vydej } from '../models/vydej';
import { Brambory } from '../models/brambory';

@Injectable({
  providedIn: 'root'
})
export class VydejService {

  actionUrl: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
    private authenticationService: AuthenticationService
  ) {
    this.actionUrl = appSettings.config.actionUrl;
  }

  getCategories(): Observable<Array<any>> {
    const categories: Array<any> = [];
    const url = this.actionUrl + "/U_ABIACZ_SURKAT?$filter=U_ABIACZ_SHOWINAPP eq 'Y'&$select=Code,Name&$orderby=Name asc";
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      items.value.forEach((element: { Code: string; Name: string; }) => {
        const category: Category = new Category();
        category.code = element.Code;
        category.name = element.Name;
        categories.push(category);
      });

      
      return categories;
    }));
  }

  getItemsInCategory(categoryCode: string, categoryName: string): Observable<any> {
    const category: any = new Category();
    category.code = categoryCode;
    category.name = categoryName;
    category.categoryItems = [];

    const url = this.actionUrl + `/Items?$filter=U_ABIACZ_SURKAT eq '${categoryCode}' and QuantityOnStock gt 0&$select=ItemCode,ItemName,ManageBatchNumbers,QuantityOnStock&$orderby=ItemName asc`;
    console.log(`Category code is ${categoryCode}`);
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }

    return this.http.get<any>(url, options)
    .pipe(map(categoryItems => {
      categoryItems.value.forEach((element: { ItemCode: any; ItemName: any; ManageBatchNumbers: any; QuantityOnStock: any }) => {
        const categoryItem = new CategoryItem();
        categoryItem.parentCode = categoryCode;
        categoryItem.parentName = categoryName;
        categoryItem.itemCode = element.ItemCode;
        categoryItem.itemName = element.ItemName;
        categoryItem.manageBatchNumbers = element.ManageBatchNumbers;
        categoryItem.quantityOnStock = element.QuantityOnStock;
        category.categoryItems.push(categoryItem);
      });

      return category;
    }));
  }

  getBatches(itemCode: string) {
    const url = this.actionUrl + `/SQLQueries('sql01')/List?ItemCode='${itemCode}'`;
    console.log(`Item code is ${itemCode}`);
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }

    return this.http.get<Batch[]>(url, options)
    .pipe(map(bachItems => {

      return bachItems;
    }))
  }

  kontrolaExistenceDokladu(employeeID: number, smena: string) {
    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');

    // Fake
    // employeeID = 13;
    // smena = 'R';
    // fromatedDate = '2023-06-20';

    const url = this.actionUrl + `/ABIACZ_OSDV/$count?$filter=U_ABIACZ_USER eq '${employeeID}' and U_ABIACZ_DOCDATE eq ${formatedDate} and U_ABIACZ_SHIFT eq '${smena}'`;
    console.log('URL :', url);

    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }

    return this.http.get<any>(url, options)
    .pipe(map(exists => {

      return (exists === 1) ? true : false;
    }))
  }

  zalozeniDokladu(employeeID: number, smena: string, vydej: Vydej) {
    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');

    const currentDate = new Date();
    const currentFormatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    const formatedTime = formatDate(currentDate, 'HH:mm:ss', 'en');
    const url = this.actionUrl + `/ABIACZ_OSDV`;

    const options = {
      withCredentials: true
    }
    const doklad = new Doklad();
    doklad.U_ABIACZ_USER = employeeID.toString();
    doklad.U_ABIACZ_SHIFT = smena;
    doklad.U_ABIACZ_PC = '';
    doklad.U_ABIACZ_Status = 'O';
    doklad.U_ABIACZ_DOCDATE = formatedDate;
    const collection = new ABIACZ_SDV1Collection();
    collection.U_ABIACZ_ITEMCODE = vydej.itemCode;
    collection.U_ABIACZ_ITEMNAME = vydej.itemName
    collection.U_ABIACZ_QTY = Number(vydej.mnozstvi);
    collection.U_ABIACZ_BATCH = vydej.batchNum;
    collection.U_ABIACZ_DATE = currentFormatedDate;
    collection.U_ABIACZ_TIME = formatedTime;
    collection.U_ABIACZ_SCRAP = Number(vydej.vadneKusy);
    collection.U_ABIACZ_FROM = vydej.spotrebaOd;
    collection.U_ABIACZ_TO = vydej.spotrebaDo;

    doklad.ABIACZ_SDV1Collection = [];
    doklad.ABIACZ_SDV1Collection.push(collection);

    const body = JSON.stringify(doklad);

    return this.http.post<any>(url, body, options)
    .pipe(map(result => {

      return result;
    }))
  }

  zalozeniDokladu2(employeeID: number, smena: string, brambory: Brambory) {
    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');

    const currentDate = new Date();
    const currentFormatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    const formatedTime = formatDate(currentDate, 'HH:mm:ss', 'en');
    const url = this.actionUrl + `/ABIACZ_OSDV`;

    const options = {
      withCredentials: true
    }
    const doklad = new Doklad();
    doklad.U_ABIACZ_USER = employeeID.toString();
    doklad.U_ABIACZ_SHIFT = smena;
    doklad.U_ABIACZ_PC = '';
    doklad.U_ABIACZ_Status = 'O';
    doklad.U_ABIACZ_DOCDATE = formatedDate;
    const collection = new ABIACZ_SDV1Collection();
    collection.U_ABIACZ_ITEMCODE = brambory.itemCode;
    collection.U_ABIACZ_ITEMNAME = brambory.itemName
    collection.U_ABIACZ_QTY = Number(brambory.mnozstvi);
    collection.U_ABIACZ_BATCH = brambory.batchNum;
    collection.U_ABIACZ_DATE = currentFormatedDate;
    collection.U_ABIACZ_TIME = formatedTime;
    // collection.U_ABIACZ_SCRAP = Number(brambory.vadneKusy);
    collection.U_ABIACZ_FROM = brambory.spotrebaOd;
    collection.U_ABIACZ_TO = brambory.spotrebaDo;

    doklad.ABIACZ_SDV1Collection = [];
    doklad.ABIACZ_SDV1Collection.push(collection);

    const body = JSON.stringify(doklad);

    return this.http.post<any>(url, body, options)
    .pipe(map(result => {

      return result;
    }))
  }

  nacteniDokladu(employeeID: number, smena: string) {
    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');

    // Fake
    // employeeID = 13;
    // smena = 'R';
    // fromatedDate = '2023-06-20';

    const url = this.actionUrl + `/ABIACZ_OSDV?$filter=U_ABIACZ_USER eq '${employeeID}' and U_ABIACZ_DOCDATE eq ${formatedDate} and U_ABIACZ_SHIFT eq '${smena}'`;
    console.log('URL :', url);

    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }

    return this.http.get<any>(url, options)
    .pipe(map(doklad => {

      return doklad;
    }))
  }

  pridaniRadky(docEntry: number, vydej: Vydej) {
    const currentDate = new Date();
    const currentFormatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    const formatedTime = formatDate(currentDate, 'HH:mm:ss', 'en');
    const url = this.actionUrl + `/ABIACZ_OSDV(${docEntry})`;
    console.log('URL :', url);

    const options = {
      withCredentials: true
    }

    const doklad = new ABIACZ_SDV1Collection();
    doklad.U_ABIACZ_ITEMCODE = vydej.itemCode;
    doklad.U_ABIACZ_ITEMNAME = vydej.itemName
    doklad.U_ABIACZ_QTY = Number(vydej.mnozstvi);
    doklad.U_ABIACZ_BATCH = vydej.batchNum;
    doklad.U_ABIACZ_DATE = currentFormatedDate;
    doklad.U_ABIACZ_TIME = formatedTime;
    doklad.U_ABIACZ_SCRAP = Number(vydej.vadneKusy);
    doklad.U_ABIACZ_FROM = vydej.spotrebaOd;
    doklad.U_ABIACZ_TO = vydej.spotrebaDo;


    const dokladJSON = JSON.stringify(doklad);
    const body = `{
      "ABIACZ_SDV1Collection": [
        ${dokladJSON}
      ]
    }`;

    return this.http.patch<any>(url, body, options)
    .pipe(map(result => {
      localStorage.setItem('doklad', JSON.stringify(dokladJSON));
      return result;
    }))
  }

  pridaniRadky2(docEntry: number, brambory: Brambory) {
    const currentDate = new Date();
    const currentFormatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    const formatedTime = formatDate(currentDate, 'HH:mm:ss', 'en');
    const url = this.actionUrl + `/ABIACZ_OSDV(${docEntry})`;
    console.log('URL :', url);

    const options = {
      withCredentials: true
    }

    const doklad = new ABIACZ_SDV1Collection();
    doklad.U_ABIACZ_ITEMCODE = brambory.itemCode;
    doklad.U_ABIACZ_ITEMNAME = brambory.itemName
    doklad.U_ABIACZ_QTY = Number(brambory.mnozstvi);
    doklad.U_ABIACZ_BATCH = brambory.batchNum;
    doklad.U_ABIACZ_DATE = currentFormatedDate;
    doklad.U_ABIACZ_TIME = formatedTime;
    // doklad.U_ABIACZ_SCRAP = Number(brambory.vadneKusy);
    doklad.U_ABIACZ_FROM = brambory.spotrebaOd;
    doklad.U_ABIACZ_TO = brambory.spotrebaDo;


    const dokladJSON = JSON.stringify(doklad);
    const body = `{
      "ABIACZ_SDV1Collection": [
        ${dokladJSON}
      ]
    }`;

    return this.http.patch<any>(url, body, options)
    .pipe(map(result => {
      localStorage.setItem('doklad', JSON.stringify(dokladJSON));
      return result;
    }))
  }

  vymazRadky(vydej: Vydej[]) {
    const currentDate = new Date();
    const currentFormatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    const formatedTime = formatDate(currentDate, 'HH:mm:ss', 'en');

    const doklad = new Array<ABIACZ_SDV1Collection>();
    let url = '';

    const options = {
      headers: new HttpHeaders({
        'B1S-ReplaceCollectionsOnPatch': 'true'
       }),
      withCredentials: true
    }

    vydej.forEach((item: Vydej) => {

      url = this.actionUrl + `/ABIACZ_OSDV(${item.docEntry})`;
      //console.log('URL :', url);

      const dokladItem = new ABIACZ_SDV1Collection();
      dokladItem.DocEntry = item.docEntry;
      dokladItem.LineId = item.lineId;
      dokladItem.VisOrder = item.visOrder;
      dokladItem.Object = item.object;
      dokladItem.U_ABIACZ_ITEMCODE = item.itemCode;
      dokladItem.U_ABIACZ_ITEMNAME = item.itemName
      dokladItem.U_ABIACZ_QTY = Number(item.mnozstvi);
      dokladItem.U_ABIACZ_BATCH = item.batchNum;
      dokladItem.U_ABIACZ_DATE = currentFormatedDate;
      dokladItem.U_ABIACZ_TIME = formatedTime;
      dokladItem.U_ABIACZ_SCRAP = Number(item.vadneKusy);
      dokladItem.U_ABIACZ_FROM = item.spotrebaOd;
      dokladItem.U_ABIACZ_TO = item.spotrebaDo;
      doklad.push(dokladItem);
    });

    const dokladJSON = JSON.stringify(doklad);
    const body = `{
      "ABIACZ_SDV1Collection":
        ${dokladJSON}
    }`;

    return this.http.patch<any>(url, body, options)
          .subscribe(
            res => {
              console.log('HTTP response', res);
              localStorage.setItem('doklad', JSON.stringify(vydej));
              location.reload();
            },
            err => console.log('HTTP Error', err),
            () => console.log('HTTP request completed.')

          );
    // .pipe(map(result => {

    //   return result;
    // }),
    // catchError(err => {
    //   console.error(err.error.message);
    //   return of([]);
    // })
    // )

  }

}
