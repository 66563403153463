<div class="breadcrumb">
Výdej materiálu - {{breadcrumbFormatedDate}} - {{breadCrumbSmena}}
</div>

<div class="left">
    <fdp-table #categoryTable
        [dataSource]="source"
        [isTreeTable]="true"
        [enableRowReordering]="false"
        selectionMode="none"
        relationKey="categoryItems"
        emptyTableMessage="No data found"
        (rowSelectionChange)="onRowSelectionChange($event)"
        (rowToggleOpenState)="onRowToggleOpenState($event)"
        (rowNavigate)="onRowNavigate($event)"
        (rowsRearrange)="onRowsRearrange($event)"
        (rowActivate)="onRowActivate($event)"
        (onDataReceived)="onDataReceived()"
        bodyHeight="700px"
        [virtualScroll]="false"
        [expandOnInit]="false"
        rowNavigatable="navigatable"
        [highlightNavigatedRow]="true"
        [rowsActivable]="true"
        >

        <fdp-column name="name" key="name" label="Kategorie" width="150px"> </fdp-column>
        <fdp-column name="itemName" key="itemName" label="Název"> </fdp-column>
    </fdp-table>
</div>

<div class="right">
    <table fd-table aria-label="Focusable">
        <thead fd-table-header>
            <tr fd-table-row>
                <th fd-table-cell>Název</th>
                <th fd-table-cell>Šarže</th>
                <th fd-table-cell>Množství</th>
                <th fd-table-cell>Spotřeba od</th>
                <th fd-table-cell>Spotřeba do</th>
                <th fd-table-cell>Vadné</th>
                <th fd-table-cell></th>
            </tr>
        </thead>
        <tbody fd-table-body>
            <tr fd-table-row [focusable]="true" *ngFor="let item of vydej">
                <td fd-table-cell>{{ item.itemName }}</td>
                <td fd-table-cell>{{ item.batchNum }}</td>
                <td fd-table-cell>{{ item.mnozstvi }}</td>
                <td fd-table-cell>{{ item.spotrebaOd }}</td>
                <td fd-table-cell>{{ item.spotrebaDo }}</td>
                <td fd-table-cell>{{ item.vadneKusy }}</td>
                <td fd-table-cell>
                    <button fd-button glyph="delete" (click)="remove(item, vydej)"></button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="modal">
    <ng-template let-dialog let-dialogConfig="dialogConfig" #confirmationDialog1>
        <fd-dialog [dialogConfig]="dialogConfig" [dialogRef]="dialog">
            <fd-dialog-body>
                <div class="first">
                    Skladem: {{ tempVydej.quantityOnStock }}
                    <table fd-table aria-label="table-one">
                        <thead fd-table-header>
                            <tr fd-table-row>
                                <th fd-table-cell>Šarže</th>
                                <th fd-table-cell>Množství</th>
                            </tr>
                        </thead>
                        <tbody fd-table-body>
                            <tr
                                *ngFor="let row of batchesRows"
                                fd-table-row
                                (click)="setActiveBatchItem(row)"
                                [ngClass]="row.active === true ? 'active': 'greyedOut'"
                                >
                                <td fd-table-cell>
                                    <p fd-table-text>
                                        {{ row.batchNum }}</p>
                                </td>
                                <td fd-table-cell>
                                    <p fd-table-text >
                                        {{ row.quantity }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="second">
                    <div fd-form-item>
                        <label fd-form-label for="fd-input-group-state-1" id="fd-input-group-state-label-1">Množství</label>
                        <fd-form-input-message-group>
                            <app-numpad [type]="NT.DISABLED" (mnozstviEvent)="setMnozstvi($event)" id="mnozstvi" *ngIf="!mnozstviEnabled && batchesRows.length > 0"/>  <!-- neni zakliknuta sarze -->
                            <app-numpad [maxPovoleneMnozstvi]="tempVydej.quantity" [type]="NT.MNOZSTVI" (mnozstviEvent)="setMnozstvi($event)" id="mnozstvi" *ngIf="mnozstviEnabled && batchesRows.length > 0"/> <!-- je zakliknuta sarze -->
                            <app-numpad [maxPovoleneMnozstvi]="tempVydej.quantityOnStock" [type]="NT.MNOZSTVI" (mnozstviEvent)="setMnozstvi($event)" id="mnozstvi" *ngIf="batchesRows.length === 0"/> <!-- uplne bez sarze -->
                        </fd-form-input-message-group>
                    </div>

                    <br />

                    <div fd-form-item>
                        <label fd-form-label for="fd-input-group-state-2" id="fd-input-group-state-label-2">Spotřeba od</label>
                        <fd-form-input-message-group>
                            <app-numpad [type]="NT.SPOTREBAOD" (spotrebaOdEvent)="setSpotrebaOd($event)" id="spotrebaod"/>
                        </fd-form-input-message-group>
                    </div>

                    <br />

                    <div fd-form-item>
                        <label fd-form-label for="fd-input-group-state-3" id="fd-input-group-state-label-3">Spotřeba do</label>
                        <fd-form-input-message-group>
                            <app-numpad [type]="NT.SPOTREBADO" (spotrebaDoEvent)="setSpotrebaDo($event)" id="spotrebado"/>
                        </fd-form-input-message-group>
                    </div>

                    <br />

                    <div fd-form-item>
                        <label fd-form-label for="fd-input-group-state-4" id="fd-input-group-state-label-4">Vadné kusy</label>
                        <fd-form-input-message-group>
                            <app-numpad [type]="NT.VADNEKUSY" (vadneKusyEvent)="setVadneKusy($event)" id="vadnekusy"/>
                        </fd-form-input-message-group>
                    </div>
                </div>
            </fd-dialog-body>

            <fd-dialog-footer>
                <input type="button" value="Přidat" (click)="dialog.close('Continue')" ariaLabel="Add" disabled="true" id="add" />
                <fd-button-bar label="Zrušit" (click)="dialog.dismiss('Cancel')" ariaLabel="Cancel"></fd-button-bar>
            </fd-dialog-footer>
        </fd-dialog>
    </ng-template>
</div>

<div class="footer">
    <input type="button" value="Zpět" (click)="back()" aria-label="Back" id="back" class="back"/>
    <input type="button" value="Konec" (click)="end()" ariaLabel="End" id="end" class="end" />
</div>

