import { AfterViewInit, Component, EventEmitter, Injectable, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef, DialogService } from '@fundamental-ngx/core/dialog';
import { first } from 'rxjs';
import { EmployeeRolesInfoLines, EmployeesInfo } from 'src/app/models/employeesInfo';
import { NumpadType } from 'src/app/models/numpadType';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-numpad',
  templateUrl: './numpad.component.html',
  styleUrls: ['./numpad.component.scss']
})

export class NumpadComponent implements AfterViewInit {
  dialogRef = new DialogRef<any, any>();
  status!: string;
  employee!: EmployeesInfo;
  okEnabled = true;
  _pin = '';
  _mnozstvi = '';
  _spotrebaOd = '';
  _spotrebaDo = '';
  _vadneKusy = '';
  _spotrebaVody = '';
  _spotrebaPlynu = '';
  _odpad = '';
  _olej = '';
  _zacatekVyroby = '';
  _konecVyroby = '';
  _dobaPrestavky = '';

  @ViewChild('confirmationDialog') customTemplate!: TemplateRef<any>;
  @Input() type!: NumpadType;
  @Input() maxPovoleneMnozstvi!: number;
  @Output() mnozstviEvent = new EventEmitter<string>();
  @Output() spotrebaOdEvent = new EventEmitter<string>();
  @Output() spotrebaDoEvent = new EventEmitter<string>();
  @Output() vadneKusyEvent = new EventEmitter<string>();
  @Output() spotrebaVodyEvent = new EventEmitter<string>();
  @Output() spotrebaPlynuEvent = new EventEmitter<string>();
  @Output() odpadEvent = new EventEmitter<string>();
  @Output() olejEvent = new EventEmitter<string>();
  @Output() zacatekVyrobyEvent = new EventEmitter<string>();
  @Output() konecVyrobyEvent = new EventEmitter<string>();
  @Output() dobaPrestavkyEvent = new EventEmitter<string>();

  @Input()
  public get pin() {
    return this._pin;
  }
  public set pin(value: string) {
    this._pin = value;
  }

  @Input()
  public get mnozstvi() {
    return this._mnozstvi;
  }
  public set mnozstvi(value: string) {
    this._mnozstvi = value;
  }

  @Input()
  public get spotrebaOd() {
    return this._spotrebaOd;
  }
  public set spotrebaOd(value: string) {
    this._spotrebaOd = value;
  }

  @Input()
  public get spotrebaDo() {
    return this._spotrebaDo;
  }
  public set spotrebaDo(value: string) {
    this._spotrebaDo = value;
  }

  @Input()
  public get vadneKusy() {
    return this._vadneKusy;
  }
  public set vadneKusy(value: string) {
    this._vadneKusy = value;
  }

  @Input()
  public get spotrebaVody() {
    return this._spotrebaVody;
  }
  public set spotrebaVody(value: string) {
    this._spotrebaVody = value;
  }

  @Input()
  public get spotrebaPlynu() {
    return this._spotrebaPlynu;
  }
  public set spotrebaPlynu(value: string) {
    this._spotrebaPlynu = value;
  }

  @Input()
  public get odpad() {
    return this._odpad;
  }
  public set odpad(value: string) {
    this._odpad = value;
  }

  @Input()
  public get olej() {
    return this._olej;
  }
  public set olej(value: string) {
    this._olej = value;
  }

  @Input()
  public get zacatekVyroby() {
    return this._zacatekVyroby;
  }
  public set zacatekVyroby(value: string) {
    this._zacatekVyroby = value;
  }

  @Input()
  public get konecVyroby() {
    return this._konecVyroby;
  }
  public set konecVyroby(value: string) {
    this._konecVyroby = value;
  }

  @Input()
  public get dobaPrestavky() {
    return this._dobaPrestavky;
  }
  public set dobaPrestavky(value: string) {
    this._dobaPrestavky = value;
  }

  constructor(
    private router: Router,
    private appSettingsService: AppSettingsService,
    private authenticationService: AuthenticationService,
    private dialog: DialogService) {}

  get title() {
    switch (this.type) {
      case NumpadType.PIN:
        return "PIN";
      case NumpadType.MNOZSTVI:
        return "Množství";
      case NumpadType.SPOTREBAOD:
        return "Spotřeba od";
      case NumpadType.SPOTREBADO:
        return "Spotřeba do";
      case NumpadType.VADNEKUSY:
        return "Vadné kusy";
      case NumpadType.SPOTREBAVODY:
        return "Spotřeba vody";
      case NumpadType.SPOTREBAPLYNU:
        return 'Spotřeba plynu';
      case NumpadType.ODPAD:
        return 'Odpad';
      case NumpadType.OLEJ:
          return 'Olej';
      case NumpadType.ZACATEKVYROBY:
        return 'Začátek výroby';
      case NumpadType.KONECVYROBY:
        return 'Konec výroby';
      case NumpadType.DOBAPRESTAVKY:
        return 'Doba přestávky';
      default:
        return null;
    }
  }

  get placeholder() {
    switch (this.type) {
      case NumpadType.PIN:
        return "PIN";
      case NumpadType.MNOZSTVI:
        return "";
      case NumpadType.SPOTREBAOD:
        return "";
      case NumpadType.SPOTREBADO:
        return "";
      case NumpadType.VADNEKUSY:
        return "";
      case NumpadType.SPOTREBAVODY:
        return "";
      case NumpadType.SPOTREBAPLYNU:
        return "";
      case NumpadType.ODPAD:
        return "";
      case NumpadType.OLEJ:
        return "";
      case NumpadType.ZACATEKVYROBY:
        return "";
      case NumpadType.KONECVYROBY:
        return "";
      case NumpadType.DOBAPRESTAVKY:
        return "";
      default:
        return null;
    }
  }

  get value() {
    switch (this.type) {
      case NumpadType.PIN:
        return this._pin;
      case NumpadType.MNOZSTVI:
        return this._mnozstvi;
      case NumpadType.SPOTREBAOD:
        return this._spotrebaOd;
      case NumpadType.SPOTREBADO:
        return this._spotrebaDo;
      case NumpadType.VADNEKUSY:
        return this._vadneKusy;
      case NumpadType.SPOTREBAVODY:
        return this._spotrebaVody;
      case NumpadType.SPOTREBAPLYNU:
        return this._spotrebaPlynu;
      case NumpadType.ODPAD:
        return this._odpad;
      case NumpadType.OLEJ:
        return this._olej;
      case NumpadType.ZACATEKVYROBY:
        return this._zacatekVyroby;
      case NumpadType.KONECVYROBY:
        return this._konecVyroby;
      case NumpadType.DOBAPRESTAVKY:
        return this._dobaPrestavky;
      default:
        return null;
    }
  }

  ngAfterViewInit(): void {
    console.log (this.type)

    const user = localStorage.getItem('user');
    if (user === null || user?.length === 0 ) {
      this.openDialog();
    }
  }

  openDialog(): void {
    this.dialogRef = this.dialog.open(this.customTemplate, {
        responsivePadding: true,
        ariaLabelledBy: 'fd-dialog-header-10',
        ariaDescribedBy: 'fd-dialog-body-10',
        focusTrapped: true
    });

    this.dialogRef.afterClosed.subscribe(
        (result: string) => {
            console.log('Dialog closed with result: ' + result);
            if (this.type === NumpadType.PIN) {
              this.pinOk();
            }
        },
        (error: string) => {
            console.log('Dialog dismissed with result: ' + error);
        }
    );
    this.status = '';
  }

  checkPin() {
    if (this.type === NumpadType.PIN) {
      this.status = '';
      console.log('Chekc PIN');
      this.pinOk();
    } else {
      this.dialogRef.close();
    }
  }

 

  managePin(command: string) {
    switch (command) {
      case 'C':
        this._pin = '';
        break;
      case '-1':
        this._pin = this._pin.slice(0, -1);
        break;
      default:
        this._pin += command;
        break;
    }
    console.log(this._pin);
  }

  manageSpotrebaOd(command: string) {
    const input = document.getElementById('spotrebaOd') as HTMLInputElement | null;

    if (this._spotrebaOd.length > 4) {
      console.log('Vyraz obsahuje nepovoleny pocet znaku.');
      switch (command) {
        case 'C':
          this._spotrebaOd = '';
          this.validateHhMm(this._spotrebaOd);
          break;
        case '-1':
          this._spotrebaOd = this._spotrebaOd.slice(0, -1);
          break;
      }
    } else {
      switch (command) {
        case 'C':
          this._spotrebaOd = '';
          break;
        case '-1':
          this._spotrebaOd = this._spotrebaOd.slice(0, -1);
          break;
        case ':':
          if (this._spotrebaOd.includes(':')) {
            console.log('Vyraz jiz obsahuje dvojtecku.');
          } else {
            this._spotrebaOd += command;
          }
          break;
        default:
          this._spotrebaOd += command;
          break;
      }
      console.log(this._spotrebaOd);

      if (input !== null)
        this.validateHhMm(this._spotrebaOd);

        this.spotrebaOdEvent.emit(this._spotrebaOd);
    }
  }

  manageSpotrebaDo(command: string) {
    const input = document.getElementById('spotrebaDo') as HTMLInputElement | null;

    if (this._spotrebaDo.length > 4) {
      console.log('Vyraz obsahuje nepovoleny pocet znaku.');
      switch (command) {
        case 'C':
          this._spotrebaDo = '';
          this.validateHhMm(this._spotrebaDo);
          break;
        case '-1':
          this._spotrebaDo = this._spotrebaDo.slice(0, -1);
          break;
      }
    } else {
      switch (command) {
        case 'C':
          this._spotrebaDo = '';
          break;
        case '-1':
          this._spotrebaDo = this._spotrebaDo.slice(0, -1);
          break;
        case ':':
          if (this._spotrebaDo.includes(':')) {
            console.log('Vyraz jiz obsahuje dvojtecku.');
          } else {
            this._spotrebaDo += command;
          }
          break;
        default:
          this._spotrebaDo += command;
          break;
      }
      console.log(this._spotrebaDo);

      if (input !== null)
        this.validateHhMm(this._spotrebaDo);

        this.spotrebaDoEvent.emit(this._spotrebaDo);
    }
  }

  manageZacatekVyroby(command: string) {
    const input = document.getElementById('zacatekVyroby') as HTMLInputElement | null;

    if (this._zacatekVyroby.length > 4) {
      console.log('Vyraz obsahuje nepovoleny pocet znaku.');
      switch (command) {
        case 'C':
          this._zacatekVyroby = '';
          this.validateHhMm(this._zacatekVyroby);
          break;
        case '-1':
          this._zacatekVyroby = this._zacatekVyroby.slice(0, -1);
          break;
      }
    } else {
      switch (command) {
        case 'C':
          this._zacatekVyroby = '';
          break;
        case '-1':
          this._zacatekVyroby = this._zacatekVyroby.slice(0, -1);
          break;
        case ':':
          if (this._zacatekVyroby.includes(':')) {
            console.log('Vyraz jiz obsahuje dvojtecku.');
          } else {
            this._zacatekVyroby += command;
          }
          break;
        default:
          this._zacatekVyroby += command;
          break;
      }
      console.log(this._zacatekVyroby);

      if (input !== null)
        this.validateHhMm(this._zacatekVyroby);

        this.zacatekVyrobyEvent.emit(this._zacatekVyroby);
    }
  }

  manageKonecVyroby(command: string) {
    const input = document.getElementById('konecVyroby') as HTMLInputElement | null;

    if (this._konecVyroby.length > 4) {
      console.log('Vyraz obsahuje nepovoleny pocet znaku.');
      switch (command) {
        case 'C':
          this._konecVyroby = '';
          this.validateHhMm(this._konecVyroby);
          break;
        case '-1':
          this._konecVyroby = this._konecVyroby.slice(0, -1);
          break;
      }
    } else {
      switch (command) {
        case 'C':
          this._konecVyroby = '';
          break;
        case '-1':
          this._konecVyroby = this._konecVyroby.slice(0, -1);
          break;
        case ':':
          if (this._konecVyroby.includes(':')) {
            console.log('Vyraz jiz obsahuje dvojtecku.');
          } else {
            this._konecVyroby += command;
          }
          break;
        default:
          this._konecVyroby += command;
          break;
      }
      console.log(this._konecVyroby);

      if (input !== null)
        this.validateHhMm(this._konecVyroby);

        this.konecVyrobyEvent.emit(this._konecVyroby);
    }
  }

  manageDobaPrestavky(command: string) {
    const input = document.getElementById('dobaPrestavky') as HTMLInputElement | null;

    if (this._dobaPrestavky.length > 4) {
      console.log('Vyraz obsahuje nepovoleny pocet znaku.');
      switch (command) {
        case 'C':
          this._dobaPrestavky = '';
          this.validateHhMm(this._dobaPrestavky);
          break;
        case '-1':
          this._dobaPrestavky = this._dobaPrestavky.slice(0, -1);
          break;
      }
    } else {
      switch (command) {
        case 'C':
          this._dobaPrestavky = '';
          break;
        case '-1':
          this._dobaPrestavky = this._dobaPrestavky.slice(0, -1);
          break;
        case ':':
          if (this._dobaPrestavky.includes(':')) {
            console.log('Vyraz jiz obsahuje dvojtecku.');
          } else {
            this._dobaPrestavky += command;
          }
          break;
        default:
          this._dobaPrestavky += command;
          break;
      }
      console.log(this._dobaPrestavky);

      if (input !== null)
        this.validateHhMm(this._dobaPrestavky);

      this.dobaPrestavkyEvent.emit(this._dobaPrestavky);
    }
  }

  validateHhMm(inputField: string) {
    const isValid = /^([01]\d|2[0-3]):?([0-5]\d)$/.test(inputField);

    if (isValid || inputField === '') {
      this.okEnabled = true;
      this.status = '';
    } else {
      this.okEnabled = false;
      this.status = 'Cas musi byt zadany ve formatu HH:mm.';
    }

    return isValid;
  }

  manageMnozstvi(command: string) {
    this.status = '';
    switch (command) {
      case 'C':
        this._mnozstvi = '';
        break;
      case '-1':
        this._mnozstvi = this._mnozstvi.slice(0, -1);
        break;
      case '.':
        if (this._mnozstvi.includes('.')) {
          console.log('Vyraz jiz obsahuje desetinnou tecku.');
        } else {
          this._mnozstvi += command;
        }
        break;
      default:
        this._mnozstvi += command;
        break;
    }
    console.log(this._mnozstvi);
    if (Number(this._mnozstvi) > this.maxPovoleneMnozstvi) {
      this.status = `Bylo překročeno povolene mnozstvi ${this.maxPovoleneMnozstvi}`;
      this._mnozstvi = '';
    } else {
      this.mnozstviEvent.emit(this._mnozstvi);
    }
  }

  manageVadneKusy(command: string) {
    switch (command) {
      case 'C':
        this._vadneKusy = '';
        break;
      case '-1':
        this._vadneKusy = this._vadneKusy.slice(0, -1);
        break;
      case '.':
        if (this._vadneKusy.includes('.')) {
          console.log('Vyraz jiz obsahuje desetinnou tecku.');
        } else {
          this._vadneKusy += command;
        }
        break;
      default:
        this._vadneKusy += command;
        break;
    }
    console.log(this._vadneKusy);
    this.vadneKusyEvent.emit(this._vadneKusy);
  }

  manageSpotrebaVody(command: string) {
    switch (command) {
      case 'C':
        this._spotrebaVody = '';
        break;
      case '-1':
        this._spotrebaVody = this._spotrebaVody.slice(0, -1);
        break;
      case '.':
         if (this._spotrebaVody.includes('.')) {
           console.log('Vyraz jiz obsahuje desetinnou tecku.');
         } else {
          this._spotrebaVody += command;
         }
        break;
      default:
        this._spotrebaVody += command;
        break;
    }
    console.log(this._spotrebaVody);
    this.spotrebaVodyEvent.emit(this._spotrebaVody);
  }

  manageSpotrebaPlynu(command: string) {
    switch (command) {
      case 'C':
        this._spotrebaPlynu = '';
        break;
      case '-1':
        this._spotrebaPlynu = this._spotrebaPlynu.slice(0, -1);
        break;
      case '.':
        if (this._spotrebaPlynu.includes('.')) {
          console.log('Vyraz jiz obsahuje desetinnou tecku.');
        } else {
          this._spotrebaPlynu += command;
        }
        break;
      default:
        this._spotrebaPlynu += command;
        break;
    }
    console.log(this._spotrebaPlynu);
    this.spotrebaPlynuEvent.emit(this._spotrebaPlynu);
  }

  manageOdpad(command: string) {
    switch (command) {
      case 'C':
        this._odpad = '';
        break;
      case '-1':
        this._odpad = this._odpad.slice(0, -1);
        break;
      case '.':
        if (this._odpad.includes('.')) {
          console.log('Vyraz jiz obsahuje desetinnou tecku.');
        } else {
          this._odpad += command;
        }
        break;
      default:
        this._odpad += command;
        break;
    }
    console.log(this._odpad);
    this.odpadEvent.emit(this._odpad);
  }

  manageOlej(command: string) {
    switch (command) {
      case 'C':
        this._olej = '';
        break;
      case '-1':
        this._olej = this._olej.slice(0, -1);
        break;
      case '.':
        if (this._olej.includes('.')) {
          console.log('Vyraz jiz obsahuje desetinnou tecku.');
        } else {
          this._olej += command;
        }
        break;
      default:
        this._olej += command;
        break;
    }
    console.log(this._olej);
    this.olejEvent.emit(this._olej);
  }

  // obsolete
  addSpotrebaOd() {
    const input = document.getElementById('spotrebaOdModal') as HTMLInputElement | null;
    const value = input?.value;
    console.log(value);
    this.spotrebaOdEvent.emit(value);
  }

  // obsolete
  addSpotrebaDo() {
    const input = document.getElementById('spotrebaDoModal') as HTMLInputElement | null;
    const value = input?.value;
    console.log(value);
    this.spotrebaDoEvent.emit(value);
  }

  // obsolete
  addZacatekVyroby() {
    const input = document.getElementById('zacatekVyrobyModal') as HTMLInputElement | null;
    const value = input?.value;
    console.log(value);
    this.zacatekVyrobyEvent.emit(value);
  }

  // obsolete
  addKonecVyroby() {
    const input = document.getElementById('konecVyrobyModal') as HTMLInputElement | null;
    const value = input?.value;
    console.log(value);
    this.konecVyrobyEvent.emit(value);
  }

  // obsolete
  addDobaPrestavky() {
    const input = document.getElementById('dobaPrestavkyModal') as HTMLInputElement | null;
    const value = input?.value;
    console.log(value);
    this.dobaPrestavkyEvent.emit(value);
  }

  // obsolete
  setDobaPrestavky() {
    this._dobaPrestavky = '00:00';
  }

  pinOk() {
    console.log(`PIN: ${this._pin}`);

    const username = this.appSettingsService.config.username;
    const password = this.appSettingsService.config.password;

    const user = localStorage.getItem('user');
    console.log(user);


      this.authenticationService.login(username, password)
      .pipe(first())
      .subscribe(
          user => {
              console.log(`Data: ${JSON.stringify(user)}`)

              this.authenticationService.checkPin(this._pin).subscribe({
                next: (employeesInfo: any) => {
                  if (employeesInfo.value.length > 0) {
                    console.log(`First Name: ${employeesInfo.value[0].FirstName}`);
                    console.log(`Last Name: ${employeesInfo.value[0].LastName}`);
                    console.log(`EmployeeID: ${employeesInfo.value[0].EmployeeID}`);
                    console.log(`RoleID: ${employeesInfo.value[0].EmployeeRolesInfoLines[0].RoleID}`);
                    this.employee = new EmployeesInfo();
                    this.employee.firstName = employeesInfo.value[0].FirstName;
                    this.employee.lastName = employeesInfo.value[0].LastName;
                    this.employee.employeeID = employeesInfo.value[0].EmployeeID;
                    this.employee.employeeRolesInfoLines = new EmployeeRolesInfoLines();
                    this.employee.employeeRolesInfoLines.roleID = employeesInfo.value[0].EmployeeRolesInfoLines[0].RoleID;

                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('timeout',(Date.now() + user['SessionTimeout']*60000).toString());
                    localStorage.setItem('employeeID', this.employee.employeeID.toString());
                    localStorage.setItem('roleID', this.employee.employeeRolesInfoLines.roleID.toString());

                     this.router.navigate(['/funkce'],
                       { queryParams: { employeeID: this.employee.employeeID, roleID: this.employee.employeeRolesInfoLines.roleID} });
                  }
                  else {
                    console.error('Invalid PIN!');
                    this.status = 'Neplatný PIN';
                  }
                },
                error: (err: any) => {
                  console.error(err);
                  this.status = err;
                }
              });
          },
          error => {
              console.error;
              this.status = 'error';
      });

  }
}
