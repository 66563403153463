import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public history: string[] = [];

  constructor(
    private router: Router, 
    private location: Location,
    private authenticationService: AuthenticationService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    
    this.history.pop() ?? ''; // remove last
    const current = this.history.pop() ?? '';
    if (current.length > 0) {
      if(current.includes('signin')) {
        // vymazani cache zpusobi automaticke zobrazeni modalniho okna pro zadani PINu
        this.authenticationService.flushCache();
      }
      this.router.navigateByUrl(current ?? '/');
    } else {
      this.authenticationService.flushCache();
      this.router.navigateByUrl("/");
    }
  }

  
  konec() { 
    this.authenticationService.logout();
  }
}
