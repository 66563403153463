import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
    TableComponent,
    TableDataProvider,
    TableDataSource,
    TableRowActivateEvent,
    TableRowSelectionChangeEvent,
    TableRowsRearrangeEvent,
    TableRowToggleOpenStateEvent,
    TableState
} from '@fundamental-ngx/platform/table';
import { DialogService } from '@fundamental-ngx/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { VydejService } from 'src/app/services/vydej.service';
import { Category } from 'src/app/models/category';
import { NumpadType } from 'src/app/models/numpadType';
import { Batch, Batches } from 'src/app/models/batches';
import { Vydej } from 'src/app/models/vydej';
import { Smena } from 'src/app/models/smena';

@Component({
    selector: 'app-vydej',
    templateUrl: './vydej.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class VydejComponent implements OnInit {
    @Input() smena!: Smena;
    @ViewChild('confirmationDialog1') customTemplate1!: TemplateRef<any>;
    @ViewChild('confirmationDialog2') customTemplate2!: TemplateRef<any>;
    @ViewChild('categoryTable', { read: ElementRef }) categoryTable!: ElementRef<HTMLElement>;
    @ViewChild(TableComponent) itemTable!: TableComponent;
    @ViewChild(TableComponent)
    
    table!: TableComponent;
    source!: TableDataSource<Category>;
    source2!: TableDataSource<Vydej>;
    
    categoryRows!: any[];
    categoryItemRows!: any[];
    batchesRows!:Batch[];
    categoryItems: Category[] = [];
    tempVydej:Vydej = new Vydej();
    vydej:Vydej[] = [];

    employeeID!: number;
    smenaID!: number;
    docEntry!: number;
    mnozstviEnabled!: boolean;
    breadcrumbFormatedDate!: string;
    breadCrumbSmena!: string;
    public NT = NumpadType;
    public SM = Smena;
    
    constructor(
      private dialog: DialogService,
      private router: Router,
      private route: ActivatedRoute,
      private navigation: NavigationService,
      private vydejService: VydejService
    ) {

    }

    ngOnInit() {
      this.route.queryParamMap.subscribe((params) => {
          console.log(params);
          this.employeeID = Number(params.get('employeeID'));
          this.smenaID = Number(params.get('smena'));
          this.docEntry = Number(params.get('docEntry'));
          console.log('Smena :', this.SM[this.smenaID]);

          // Kontrola cookie - pokud se lisi, muze to byt zpusobene refreshem stranky, pak ma 
          // hodnota uloznea v cookie prednost
          if (this.employeeID !== Number(localStorage.getItem('employeeID')) || this.smenaID !== Number(localStorage.getItem('smena')) || this.docEntry !== Number(localStorage.getItem('docEntry'))) {
            this.employeeID = Number(localStorage.getItem('employeeID'));
            this.smenaID = Number(localStorage.getItem('smena'));
            this.docEntry = Number(localStorage.getItem('docEntry'));
          }

          // Nacteni kategorii z cookie
          const categories = localStorage.getItem('categoryItems');
          this.categoryItems = JSON.parse(categories ?? '');
          this.source = new TableDataSource(new TableDataProviderCategory(this.categoryItems));

          // Nacteni datumu z cookie
          this.breadcrumbFormatedDate = localStorage.getItem('formatedDate') ?? '';
          let smena = '';
          switch(this.SM[this.smenaID]) {
            case 'R':
              smena = 'ranní';
              break;
            case 'O':
              smena = 'odpolední';
              break;
            case 'N':
              smena = 'noční';
              break;
            default:
              smena = '';
              break;
          }
          this.breadCrumbSmena = smena;

          // Nacteni dokladu z cookie pokud existuje
          const doklad = localStorage.getItem('doklad');
          if (doklad === null) {
            this.source2 = new TableDataSource(new TableDataProviderVydej(this.vydej));
          } else {
            this.vydej = JSON.parse(doklad ?? '');
            this.source2 = new TableDataSource(new TableDataProviderVydej(this.vydej));
          }
        }
      );
      
    }

    alert(message: string): void {
        alert(message);
    }

    onRowToggleOpenState(event: TableRowToggleOpenStateEvent<any>): void {
        console.log(event);
    }

    onRowsRearrange(event: TableRowsRearrangeEvent<any>): void {
        console.log(event);
    }

    toggleFirstRow(): void {
        this.table.toggleGroupRows(0);
    }

    onRowSelectionChange(event: TableRowSelectionChangeEvent<any>) {
        console.log(event);
    }

    onRowNavigate(event: TableRowActivateEvent<any>): void {
        console.log(event);
    }

    onDataReceived(): void {
      console.log('onDataReceived');
    }

    onRowActivate(event: TableRowActivateEvent<any>): void {
        console.log(event);
        
        if (event.row.itemCode !== undefined) {

            this.vydejService.getBatches(event.row.itemCode).subscribe({
                next: (val: any) => {
                  console.log(val.value);
                  const batches: Batches = new Batches();
                  batches.items = [];
                  val.value.forEach((row: { BatchNum: string; Quantity: string; }) => {
                    const batch: Batch = new Batch();
                    batch.batchNum = row.BatchNum;
                    batch.quantity = Number(row.Quantity);
                    batch.active = false;

                    batches.items.push(batch);
                  });

                  this.batchesRows = batches.items;

                  this.itemsInCategory(event.row.parentCode, event.row.itemName, event.row.itemCode, event.row.manageBatchNumbers, event.row.quantityOnStock);
                },
                error: (err: any) => {
                  console.error(err);
                }
              });
        }
    }

    itemsInCategory(categoryCode: string, itemName: string, itemCode: string, manageBatchNumbers: string, quantityOnStock: number) {
      // cleaning temporary vydej 
      this.tempVydej = new Vydej();

      // fill temporary vydej
      this.tempVydej.itemName = itemName;
      this.tempVydej.itemCode = itemCode;
      this.tempVydej.categoryCode = categoryCode;
      this.tempVydej.manageBatchNumbers = manageBatchNumbers;
      this.tempVydej.quantityOnStock = quantityOnStock;

      const dialogRef = this.dialog.open(this.customTemplate1, {
        responsivePadding: true,
        ariaLabelledBy: 'table-one',
        focusTrapped: true
      });
  
      dialogRef.afterClosed.subscribe(
          (result: string) => {
              console.log('Dialog closed with result: ' + result);
              console.log(this.tempVydej.batchNum, this.tempVydej.quantity, this.tempVydej.mnozstvi, this.tempVydej.spotrebaOd, this.tempVydej.spotrebaDo, this.tempVydej.vadneKusy);
              // Vloz zaznam do tabulky - TEMP (zapoznamkovat)
              // this.vydej.push(this.tempVydej);
              // this.source2 = new TableDataSource(new TableDataProviderVydej(this.vydej));

              // Vloz zaznam do tabulky pomoci SAPu
              // Kontrola existence dokladu - ověřit jestli existuje doklad na uživatele a směnu
              this.vydejService.kontrolaExistenceDokladu(this.employeeID, this.SM[this.smenaID]).subscribe({
                next: (exists: boolean) => {
                  console.log('Kontrola excistence dokladu :', exists);

                  if (exists) {
                    this.vydejService.pridaniRadky(Number(this.docEntry), this.tempVydej).subscribe({
                      next: (value: any) => {
                        // po uspesnem ulozeni refresh dat
                        this.nacteniDokladu(this.employeeID, this.SM[this.smenaID]);
                      },
                      error: (err: any) => {
                        console.error(err);
                      }
                    })
                  } else {
                    this.vydejService.zalozeniDokladu(this.employeeID, this.SM[this.smenaID], this.tempVydej).subscribe({
                      next: (value: any) => {
                        console.log(value);
                        // po uspesnem ulozeni refresh dat
                        this.nacteniDokladu(this.employeeID, this.SM[this.smenaID]);
                      },
                      error: (err: any) => {
                        console.error(err);
                      }
                    })
                  }
                },
                error: (err: any) => {
                  console.error(err);
                }
              });
              this.mnozstviEnabled = false;
          },
          (error: string) => {
              console.log(error);
              //this.getItemsInCategory(categoryCode);
              this.mnozstviEnabled = false;
          }
      );
    }

    batches(batchesCode: string) {
        const dialogRef = this.dialog.open(this.customTemplate2, {
          responsivePadding: true,
          ariaLabelledBy: 'table-second',
          focusTrapped: true
          
        });
        
        this.vydejService.getBatches(batchesCode).subscribe({
          next: (val: any) => {
            console.log(val.value);
            this.batchesRows = val.value;
          },
          error: (err: any) => {
            console.error(err);
          }
        });
    }
    
    setActiveBatchItem(row: any): void {
      // validace: pokud neni zakliknuta sarze, tak neumoznime vstoupit do kompoonenty mnozstvi a zadat mnozstvi
      this.mnozstviEnabled = true;

      this.batchesRows.forEach(element => {
        if (row.batchNum === element.batchNum) {
          element.active = true;
          this.tempVydej.batchNum = element.batchNum;
          this.tempVydej.quantity = Number(element.quantity);
        }
        else {
          element.active = false;
        }
      });
      this.validate();
    }
    
    remove(item: Vydej, vydej: Vydej[]) {
      console.log(`Odstraneni polozky: ${item.itemName}`);
      vydej = vydej.filter(i => i.lineId != item.lineId);
      vydej.forEach((x: Vydej) => {
        console.log(x.lineId);
      });
      this.vydejService.vymazRadky(vydej);
      const doklad = localStorage.getItem('doklad');
      this.vydej = JSON.parse(doklad ?? '');
      
    }

    add(batch: any) {
      console.log(batch);
    }

    setMnozstvi(mnozstvi: string) {
      this.tempVydej.mnozstvi = mnozstvi;
      this.validate();
    }

    setVadneKusy(vadneKusy: string) {
      this.tempVydej.vadneKusy = vadneKusy;
      this.validate();
    }

    setSpotrebaOd(spotrebaOd: string) {
      this.tempVydej.spotrebaOd = spotrebaOd;
      if (this.tempVydej.manageBatchNumbers === 'tYES') {
        this.validate();
      }
    }

    setSpotrebaDo(spotrebaDo: string) {
      this.tempVydej.spotrebaDo = spotrebaDo;
      if (this.tempVydej.manageBatchNumbers === 'tYES') {
        this.validate();
      }
    }

    validate() {
      if (this.tempVydej.manageBatchNumbers === 'tYES') {
        if(this.batchesRows.length > 0) {
          if (this.tempVydej.itemName !== undefined && 
              this.tempVydej.mnozstvi !== undefined && 
              this.tempVydej.spotrebaOd !== undefined && 
              this.tempVydej.spotrebaDo !== undefined &&
              this.tempVydej.batchNum !== undefined && 
              this.tempVydej.quantity !== undefined) {
                const input = document.getElementById('add') as HTMLInputElement;
                input.disabled = false;
            }
        } else {
          if (this.tempVydej.itemName !== undefined && 
              this.tempVydej.mnozstvi !== undefined && 
              this.tempVydej.spotrebaOd !== undefined && 
              this.tempVydej.spotrebaDo !== undefined) {
                const input = document.getElementById('add') as HTMLInputElement;
                input.disabled = false;
            }
        }
      } else {
        if(this.batchesRows.length > 0) {
          if (this.tempVydej.itemName !== undefined && 
              this.tempVydej.mnozstvi !== undefined && 
              this.tempVydej.batchNum !== undefined && 
              this.tempVydej.quantity !== undefined) {
                const input = document.getElementById('add') as HTMLInputElement;
                input.disabled = false;
            }
        } else {
          if (this.tempVydej.itemName !== undefined && 
              this.tempVydej.mnozstvi !== undefined) {
                const input = document.getElementById('add') as HTMLInputElement;
                input.disabled = false;
            }
        }
      }
    }

    nacteniDokladu(employeeID: number, smena: string) {
      // promazani zaznamu tabulky
       this.vydej = [];
      // this.source2 = new TableDataSource(new TableDataProviderVydej(this.vydej));
      
      this.vydejService.nacteniDokladu(employeeID, smena).subscribe({
        next: (doklad: any) => {
          console.log('Doklad :', doklad);

          doklad.value[0].ABIACZ_SDV1Collection.forEach((element: any) => {
            console.log(element);
            this.tempVydej.lineId = element.LineId;
            this.tempVydej.visOrder = element.VisOrder;
            this.tempVydej.object = element.Object;
            this.tempVydej.itemCode = element.U_ABIACZ_ITEMCODE;
            this.tempVydej.itemName = element.U_ABIACZ_ITEMNAME;
            this.tempVydej.batchNum = element.U_ABIACZ_BATCH;
            this.tempVydej.quantity = element.U_ABIACZ_QTY;
            this.tempVydej.mnozstvi = element.U_ABIACZ_QTY;
            this.tempVydej.spotrebaOd = element.U_ABIACZ_FROM;
            this.tempVydej.spotrebaDo = element.U_ABIACZ_TO;
            this.tempVydej.vadneKusy = element.U_ABIACZ_SCRAP;
            this.tempVydej.docEntry = element.DocEntry;
            this.docEntry = element.DocEntry;
            this.vydej.push(this.tempVydej);
            this.tempVydej = new Vydej();
          });
        
          localStorage.setItem('docEntry', JSON.stringify(this.docEntry));
          localStorage.setItem('doklad', JSON.stringify(this.vydej));

          this.source2 = new TableDataSource(new TableDataProviderVydej(this.vydej));
          location.reload();
        },
        error: (err: any) => {
          console.error(err);
        }
      })
    }

    back(): void {
      this.navigation.back();
    }

    end() {
      console.log('Konec');
      this.navigation.konec();
    }
}

export class TableDataProviderCategory extends TableDataProvider<Category> {
    override items: Category[] = [];
    override totalItems = this.items.length;
    ITEMS: Category[] = [];

    constructor(categoryItems: Category[]) {
        super();
        this.ITEMS = categoryItems;
        this.items = [...categoryItems];
    }

    override fetch(tableState?: TableState): Observable<Category[]> {
        this.items = this.items = [...this.ITEMS];

        // apply searching
        if (tableState?.searchInput) {
            this.items = this.search(this.items, tableState);
        }

        this.totalItems = this.items.length;

        return of(this.items);
    }

    override search(items: Category[], { searchInput, columnKeys }: TableState): Category[] {
        const searchText = searchInput?.text || '';
        const keysToSearchBy = columnKeys;

        if (searchText.trim() === '' || keysToSearchBy.length === 0) {
            return items;
        }

        return items.filter((item) => {
            const valuesForSearch = keysToSearchBy.map((key) => getNestedValue(key, item));
            return valuesForSearch
                .filter((value) => !!value)
                .map((value): string => value.toString())
                .some((value) => value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        });
    }
}

export class TableDataProviderVydej extends TableDataProvider<Vydej> {
  override items: Vydej[] = [];
  override totalItems = this.items.length;
  ITEMS: Vydej[] = [];

  constructor(vydejItems: Vydej[]) {
      super();
      this.ITEMS = vydejItems;
      this.items = [...vydejItems];
  }

  override fetch(tableState?: TableState): Observable<Vydej[]> {
      this.items = this.items = [...this.ITEMS];

      // apply searching
      if (tableState?.searchInput) {
          this.items = this.search(this.items, tableState);
      }

      this.totalItems = this.items.length;

      return of(this.items);
  }

  override search(items: Vydej[], { searchInput, columnKeys }: TableState): Vydej[] {
      const searchText = searchInput?.text || '';
      const keysToSearchBy = columnKeys;

      if (searchText.trim() === '' || keysToSearchBy.length === 0) {
          return items;
      }

      return items.filter((item) => {
          const valuesForSearch = keysToSearchBy.map((key) => getNestedValue(key, item));
          return valuesForSearch
              .filter((value) => !!value)
              .map((value): string => value.toString())
              .some((value) => value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
      });
  }
}

function getNestedValue<T extends Record<string, any>>(key: string, object: T): any {
    return key.split('.').reduce((a, b) => (a ? a[b] : null), object);
}



