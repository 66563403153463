import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private http!: HttpClient;
  config: any;

  constructor(handler: HttpBackend) { 
    this.http = new HttpClient(handler);
  }

  public load() {
    console.log('Loading Application Settings')
    return this.http.get('././assets/config/config.json').pipe(
      tap(config => this.config = config)
    );
  }
}
