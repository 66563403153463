export class EditaceSmeny {
    DocEntry!: number;
    LineId!: number;
    U_ABIACZ_BATCH!: string;
    U_ABIACZ_DATE!: number;
    U_ABIACZ_FROM!: number;
    U_ABIACZ_ITEMNAME!: string;
    U_ABIACZ_QTY!: number;
    U_ABIACZ_SCRAP!: number;
    U_ABIACZ_TIME!: number;
    U_ABIACZ_TO!: number;
    firstName!: string;
    lastName!: string;
}