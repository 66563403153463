import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action } from 'src/app/models/action';
import { EmployeesInfo } from 'src/app/models/employeesInfo';
import { Sklad } from 'src/app/models/sklad';
import { NavigationService } from 'src/app/services/navigation.service';
import { VyrobkySklademService } from 'src/app/services/vyrobky-skladem.service';

@Component({
  selector: 'app-funkce',
  templateUrl: './funkce.component.html',
  styleUrls: ['./funkce.component.scss']
})
export class FunkceComponent implements OnInit {
 
  employeeID!: number;
  roleID!: number;
  public AC = Action;
  actionID!: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigation: NavigationService,
    private vyrobkySklademService: VyrobkySklademService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
        console.log(params);
        this.employeeID = Number(params.get('employeeID'));
        this.roleID = Number(params.get('roleID'));
        console.log('EmployeeID :', this.employeeID);
        console.log('RoleID :', this.roleID);
      }
    );

    // Once both are properly loaded then display buttons
    this.vyrobkySklademService.getSkladBrand().subscribe({
      next: (skladBrandItems: Array<any>) => {
        console.log(`skladBrandItems: ${skladBrandItems}`);
        localStorage.setItem('skladBrandItems', JSON.stringify(skladBrandItems));

        this.vyrobkySklademService.getSkladOwn().subscribe({
          next: (skladOwnItems: Array<any>) => {
            console.log(`skladOwnItems: ${skladOwnItems}`);
            localStorage.setItem('skladOwnItems', JSON.stringify(skladOwnItems));
          },
          error: (err: any) => {
            console.error(err);
          },
          complete() {
            // Will not be called, even when cancelling subscription.
            console.log('completed!');
            
            // display buttons
            const menu = document.getElementById('menu-funkce') as HTMLInputElement;
            menu.style.display = 'block';
            
          }
        });
      },
      error: (err: any) => {
        console.error(err);
      }
    });
  }

  vydejMaterialu(action: Action) {
    // Ulozeni action do cookie
    localStorage.setItem('action', action.toString());

    // prevod na ID action
    this.actionID = Number(action);

    this.router.navigate(['/smena'], 
    { queryParams: { employeeID: this.employeeID, actionID: this.actionID } });
  }

  ukonceniSmeny(action: Action) {
    // Ulozeni action do cookie
    localStorage.setItem('action', action.toString());

    // prevod na ID action
    this.actionID = Number(action);
    
    this.router.navigate(['/smena'], 
    { queryParams: { employeeID: this.employeeID, actionID: this.actionID } });
  }

  vyrobkySkladem(action: Action) {
    // Ulozeni action do cookie
    localStorage.setItem('action', action.toString());

    // prevod na ID action
    this.actionID = Number(action);
    
    this.router.navigate(['/vyrobky-skladem'], 
    { queryParams: { employeeID: this.employeeID, actionID: this.actionID } });
  }

  editaceSmeny(action: Action) {
    // Ulozeni action do cookie
    localStorage.setItem('action', action.toString());

    // prevod na ID action
    this.actionID = Number(action);
    
    this.router.navigate(['/smena'], 
    { queryParams: { employeeID: this.employeeID, actionID: this.actionID } });
  }

  back(): void {
    this.navigation.back();
  }

  end() {
    console.log('Konec');
    this.navigation.konec();
  }
}
