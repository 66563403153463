export class Category {
    code!: string;
    name!: string;
    categoryItems!: CategoryItem[];
}

export class CategoryItem {
    parentCode!: string;
    parentName!: string;
    itemCode!: string;
    itemName!: string;
    manageBatchNumbers!: string;
    quantityOnStock!: number;
    batchNumber!: string;
    quantity!: string;
}