<div class="breadcrumb">
    Ukončení směny - {{breadcrumbFormatedDate}} - {{breadCrumbSmena}}
    </div>

<div id="ukonceni-smeny-left-table">
    <table fd-table>
        <thead fd-table-header>
            <tr fd-table-row>
                <th fd-table-cell>Název</th>
                <th fd-table-cell>Plánované množství</th>
                <th fd-table-cell>Reálné množství</th>
                <th fd-table-cell>Rozdíl</th>
            </tr>
        </thead>
        <tbody fd-table-body>
            <tr fd-table-row [focusable]="true" *ngFor="let item of seznamProVydej" [ngClass]="{ 'highlight': item.Tolerance === undefined || (item.Tolerance > 0 && item.AbsolutePercentualDifference > item.Tolerance) }">
                <td fd-table-cell>{{ item.U_ABIACZ_ITEMNAME }}</td>
                <td fd-table-cell class="td-text-right">{{ item.SCRAP | number : '1.2-2' }}</td>
                <td fd-table-cell class="td-text-right">{{ item.QTY | number : '1.2-2' }}</td>
                <td fd-table-cell class="td-text-right">{{ item.DIFFERENCE | number : '1.2-2' }}</td>
            </tr>
        </tbody>
    </table>
</div>

<div id="ukonceni-smeny-right-table">
    <table>
        <thead fd-table-header>
            <tr fd-table-row>
                <th fd-table-cell>Vyrobeno</th>
                <th fd-table-cell>Množství</th>
                <th fd-table-cell>Kartony</th>
            </tr>
        </thead>
        <tbody fd-table-body>
            <tr fd-table-row [focusable]="true" *ngFor="let item of seznamVyroba">
                <td fd-table-cell>{{ item.Dscription }}</td>
                <td fd-table-cell>{{ item.Quantity }}</td>
                <td fd-table-cell>{{ item.InvQty }}</td>
            </tr>
        </tbody>
    </table>
</div>

<div id="ukonceni-smeny-custom-table">
    <div class="custom-left">
        <label fd-form-label for="spotreba-vody" id="spotreba-vody-label">Spotřeba vody</label>
        <fd-form-input-message-group>
            <app-numpad [type]="NT.SPOTREBAVODY" (spotrebaVodyEvent)="setSpotrebaVody($event)" id="spotrebaVody"/>
        </fd-form-input-message-group>

        <label fd-form-label for="spotreba-plynu" id="spotreba-plynu-label">Spotřeba plynu</label>
        <fd-form-input-message-group>
            <app-numpad [type]="NT.SPOTREBAPLYNU" (spotrebaPlynuEvent)="setSpotrebaPlynu($event)" id="spotrebaPlynu"/>
        </fd-form-input-message-group>

        <label fd-form-label for="odpad" id="odpad-label">Odpad</label>
        <fd-form-input-message-group>
            <app-numpad [type]="NT.ODPAD" (odpadEvent)="setOdpad($event)" id="odpad"/>
        </fd-form-input-message-group>

        <!-- <label fd-form-label for="olej" id="olej-label">Olej</label>
        <fd-form-input-message-group>
            <app-numpad [type]="NT.OLEJ" (olejEvent)="setOlej($event)" id="olej"/>
        </fd-form-input-message-group> -->

    </div>

    <div class="custom-right">
        <label fd-form-label for="zacatek-vyroby" id="zacatek-vyroby-label">Začátek výroby</label>
        <app-numpad [type]="NT.ZACATEKVYROBY" (zacatekVyrobyEvent)="setZacatekVyroby($event)" id="zacatekVyroby"/>

        <label fd-form-label for="konec-vyroby" id="konec-vyroby-label">Konec výroby</label>
        <app-numpad [type]="NT.KONECVYROBY" (konecVyrobyEvent)="setKonecVyroby($event)" id="konecVyroby"/>

        <label fd-form-label for="doba-prestavky" id="doba-prestavky-label">Doba přestávky</label>
        <app-numpad [type]="NT.DOBAPRESTAVKY" (dobaPrestavkyEvent)="setDobaPrestavky($event)" id="dobaPrestavky"/>
    </div>
</div>

<div class="modal">
    <ng-template let-dialog let-dialogConfig="dialogConfig" #confirmationDialog1>
        <fd-dialog [dialogConfig]="dialogConfig" [dialogRef]="dialog">
            <fd-dialog-body>
                <fdp-table #typBramborTable
                            [dataSource]="bramborySource"
                            [isTreeTable]="true"
                            selectionMode="none"
                            relationKey="bramboryItems"
                            emptyTableMessage="No data found"
                            (rowActivate)="onRowActivate($event)"
                            bodyHeight="700px"
                            [virtualScroll]="false"
                            [expandOnInit]="false"
                            rowNavigatable="navigatable"
                            [highlightNavigatedRow]="true"
                            [rowsActivable]="true">

                <fdp-column name="itemName" key="itemName" label="Název"> </fdp-column>
                <fdp-column name="itemCode" key="itemCode" label="Kód"> </fdp-column>
                </fdp-table>
            </fd-dialog-body>
            <fd-dialog-footer>
                <fd-button-bar label="Zrušit" (click)="dialog.dismiss('Cancel')" ariaLabel="Cancel"></fd-button-bar>
            </fd-dialog-footer>
        </fd-dialog>
    </ng-template>

</div>

<div class="detail">
    <ng-template let-dialog let-dialogConfig="dialogConfig" #confirmationDialog2>
        <fd-dialog [dialogConfig]="dialogConfig" [dialogRef]="dialog">
            <fd-dialog-body>
                <div class="first">
                    Skladem: {{ tempBrambory.quantityOnStock }}
                    <table fd-table aria-label="table-one">
                        <thead fd-table-header>
                            <tr fd-table-row>
                                <th fd-table-cell>Šarže</th>
                                <th fd-table-cell>Množství</th>
                            </tr>
                        </thead>
                        <tbody fd-table-body>
                            <tr
                                *ngFor="let row of batchesRows"
                                fd-table-row
                                (click)="setActiveBatchItem(row)"
                                [ngClass]="row.active === true ? 'active': 'greyedOut'"
                                >
                                <td fd-table-cell>
                                    <p fd-table-text>
                                        {{ row.batchNum }}</p>
                                </td>
                                <td fd-table-cell>
                                    <p fd-table-text >
                                        {{ row.quantity }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="second">
                    <div fd-form-item>
                        <label fd-form-label for="fd-input-group-state-1" id="fd-input-group-state-label-1">Množství</label>
                        <fd-form-input-message-group>
                            <app-numpad [type]="NT.DISABLED" (mnozstviEvent)="setMnozstvi($event)" id="mnozstvi" *ngIf="!mnozstviEnabled && batchesRows.length > 0"/>  <!-- neni zakliknuta sarze -->
                            <app-numpad [maxPovoleneMnozstvi]="tempBrambory.quantity" [type]="NT.MNOZSTVI" (mnozstviEvent)="setMnozstvi($event)" id="mnozstvi" *ngIf="mnozstviEnabled && batchesRows.length > 0"/> <!-- je zakliknuta sarze -->
                            <app-numpad [maxPovoleneMnozstvi]="tempBrambory.quantityOnStock" [type]="NT.MNOZSTVI" (mnozstviEvent)="setMnozstvi($event)" id="mnozstvi" *ngIf="batchesRows.length === 0"/> <!-- uplne bez sarze -->
                        </fd-form-input-message-group>
                    </div>

                    <br />

                    <div fd-form-item>
                        <label fd-form-label for="fd-input-group-state-2" id="fd-input-group-state-label-2">Spotřeba od</label>
                        <fd-form-input-message-group>
                            <app-numpad [type]="NT.SPOTREBAOD" (spotrebaOdEvent)="setSpotrebaOd($event)" id="spotrebaod"/>
                        </fd-form-input-message-group>
                    </div>

                    <br />

                    <div fd-form-item>
                        <label fd-form-label for="fd-input-group-state-3" id="fd-input-group-state-label-3">Spotřeba do</label>
                        <fd-form-input-message-group>
                            <app-numpad [type]="NT.SPOTREBADO" (spotrebaDoEvent)="setSpotrebaDo($event)" id="spotrebado"/>
                        </fd-form-input-message-group>
                    </div>
                </div>
            </fd-dialog-body>

            <fd-dialog-footer>
                <input type="button" value="Přidat" (click)="dialog.close('Continue')" ariaLabel="Add" disabled="true" id="add" />
                <fd-button-bar label="Zrušit" (click)="dialog.dismiss('Cancel')" ariaLabel="Cancel"></fd-button-bar>
            </fd-dialog-footer>
        </fd-dialog>
    </ng-template>
</div>

<div class="seznam-expedice-modal">
    <ng-template let-dialog let-dialogConfig="dialogConfig" #confirmationDialog3>
        <fd-dialog [dialogConfig]="dialogConfig" [dialogRef]="dialog">
            <fd-dialog-body>
                <table>
                    <thead fd-table-header>
                        <tr fd-table-row>
                            <th fd-table-cell>Číslo RP</th>
                            <th fd-table-cell>Artikl</th>
                            <th fd-table-cell>Kartony</th>
                            <th fd-table-cell>Množství</th>
                        </tr>
                    </thead>
                    <tbody fd-table-body>
                        <tr fd-table-row [focusable]="true" *ngFor="let item of seznamExpedice">
                            <td fd-table-cell>{{ item.Expedice }}</td>
                            <td fd-table-cell>{{ item.Dscription }}</td>
                            <td fd-table-cell>{{ item.Quantity }}</td>
                            <td fd-table-cell>{{ item.InvQty }}</td>
                        </tr>
                    </tbody>
                </table>
            </fd-dialog-body>
            <fd-dialog-footer>
                <fd-button-bar label="Zrušit" (click)="dialog.dismiss('Cancel')" ariaLabel="Cancel"></fd-button-bar>
            </fd-dialog-footer>
        </fd-dialog>
    </ng-template>

</div>

<div class="ukonceni-modal">
    <ng-template let-dialog let-dialogConfig="dialogConfig" #confirmationDialog4>
        <fd-dialog [dialogConfig]="dialogConfig" [dialogRef]="dialog">
            <fd-dialog-body>
                {{ukonceniStatus}}
            </fd-dialog-body>
            <fd-dialog-footer>
                <fd-button-bar label="Ok" (click)="dialog.close('Ok')" ariaLabel="Ok"></fd-button-bar>
            </fd-dialog-footer>
        </fd-dialog>
    </ng-template>
</div>

<div class="mimo-toleranci-modal">
    <ng-template let-dialog let-dialogConfig="dialogConfig" #confirmationDialog5>
        <fd-dialog [dialogConfig]="dialogConfig" [dialogRef]="dialog">
            <fd-dialog-header>
                <h1 id="fd-dialog-header-10" fd-title>Položky mimo odchylku</h1>
            </fd-dialog-header>
            <fd-dialog-body>
                {{mimoToleranci}}
            </fd-dialog-body>
            <fd-dialog-footer>
                <fd-button-bar label="Ok" (click)="dialog.close('Ok')" ariaLabel="Ok"></fd-button-bar>
            </fd-dialog-footer>
        </fd-dialog>
    </ng-template>
</div>

<div class="footer">
    <input type="button" value="Konec směny" (click)="konecSmeny()" aria-label="KonecSmeny" id="konecSmeny" class="konec-smeny" disabled="true"/>
    <input type="button" value="Zpět" (click)="back()" aria-label="Back" id="back" class="back"/>
    <input type="button" value="Seznam expedice" (click)="seznamExpediceModal()" aria-label="seznamExpedice" id="seznamExpedice" class="seznam-expedice"/>

    <input type="button" value="Brambory" (click)="brambory()" aria-label="Brambory" id="brambory" class="brambory"/>
    <input type="button" value="Konec" (click)="end()" ariaLabel="End" id="end" class="end" />
</div>
