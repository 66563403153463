import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ABIACZ_SDV1Collection, Doklad } from 'src/app/models/doklad';
import { EditaceSmeny } from 'src/app/models/editaceSmeny';
import { Smena } from 'src/app/models/smena';
import { EditaceSmenyService } from 'src/app/services/editace-smeny.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-editace-smeny',
  templateUrl: './editace-smeny.component.html',
  styleUrls: ['./editace-smeny.component.scss']
})
export class EditaceSmenyComponent implements OnInit{

  employeeID!: number;
  smenaID!: number;
  docEntry!: number;
  breadcrumbFormatedDate!: string;
  breadCrumbSmena!: string;
  seznamEditaceSmeny!: Array<EditaceSmeny>;
  editacePolozkyDokladu!: Array<ABIACZ_SDV1Collection>;
  public SM = Smena;

  constructor(
    private route: ActivatedRoute,
    private navigation: NavigationService,
    private editaceSmenyService: EditaceSmenyService
  ) {

  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      console.log(params);
      this.employeeID = Number(params.get('employeeID'));
      this.smenaID = Number(params.get('smena'));
      console.log('Smena :', this.SM[this.smenaID]);

      // Kontrola cookie - pokud se lisi, muze to byt zpusobene refreshem stranky, pak ma 
      // hodnota uloznea v cookie prednost
      if (this.employeeID !== Number(localStorage.getItem('employeeID')) || this.smenaID !== Number(localStorage.getItem('smena')) || this.docEntry !== Number(localStorage.getItem('docEntry'))) {
        this.employeeID = Number(localStorage.getItem('employeeID'));
        this.smenaID = Number(localStorage.getItem('smena'));
        this.docEntry = Number(localStorage.getItem('docEntry'));
        try {
          this.seznamEditaceSmeny = JSON.parse(localStorage.getItem('seznamEditaceSmeny') ?? '');
          this.editacePolozkyDokladu = JSON.parse(localStorage.getItem('editacePolozkyDokladu') ?? '');
        } catch {
          console.error('Not all of the JSON data was received.');
        }
      }

      // Nacteni datumu z cookie
      this.breadcrumbFormatedDate = localStorage.getItem('formatedDate') ?? '';
      let smena = '';
      switch(this.SM[this.smenaID]) {
        case 'R':
          smena = 'ranní';
          break;
        case 'O':
          smena = 'odpolední';
          break;
        case 'N':
          smena = 'noční';
          break;
        default:
          smena = '';
          break;
      }
      this.breadCrumbSmena = smena;
    }
  );
  }

  remove(item: EditaceSmeny) {
    console.log(`Odstraneni polozky: ${item.U_ABIACZ_BATCH} - ${item.U_ABIACZ_ITEMNAME}`);
    const sDV1Collection = this.editacePolozkyDokladu.filter(i => i.LineId != item.LineId);
    const editaceSmeny = this.seznamEditaceSmeny.filter(i => i.LineId != item.LineId);
    console.log(sDV1Collection);

    this.editaceSmenyService.vymazRadek(this.docEntry, sDV1Collection, editaceSmeny);
  }

  back(): void {
    this.navigation.back();
  }

  end() {
    console.log('Konec');
    this.navigation.konec();
  }
}
