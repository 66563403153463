import { formatDate } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogRef, DialogService } from '@fundamental-ngx/core';
import { TableDataProvider, TableDataSource, TableRowActivateEvent, TableState } from '@fundamental-ngx/platform';
import { Observable, of } from 'rxjs';
import { Batch, Batches } from 'src/app/models/batches';
import { Brambory } from 'src/app/models/brambory';
import { Expedice } from 'src/app/models/expedice';
import { NumpadType } from 'src/app/models/numpadType';
import { OilBatch, OilBatches } from 'src/app/models/oilBatches';
import { Mnozstvi, PlanovaneMnozstvi } from 'src/app/models/planovaneMnozstvi';
import { Smena } from 'src/app/models/smena';
import { VydejNaSmenu } from 'src/app/models/vydejNaSmenu';
import { BatchNumber, DocumentLine, Vydejka } from 'src/app/models/vydejka';
import { Vyroba } from 'src/app/models/vyroba';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UkonceniSmenyService } from 'src/app/services/ukonceni-smeny.service';
import { VydejService } from 'src/app/services/vydej.service';

@Component({
  selector: 'app-ukonceni-smeny',
  templateUrl: './ukonceni-smeny.component.html',
  styleUrls: ['./ukonceni-smeny.component.scss']
})
export class UkonceniSmenyComponent implements OnInit {

  @ViewChild('confirmationDialog1') customTemplate1!: TemplateRef<any>;
  @ViewChild('confirmationDialog2') customTemplate2!: TemplateRef<any>;
  @ViewChild('confirmationDialog3') customTemplate3!: TemplateRef<any>;
  @ViewChild('confirmationDialog4') customTemplate4!: TemplateRef<any>;
  @ViewChild('confirmationDialog5') customTemplate5!: TemplateRef<any>;

  bramboryModalDialog!: DialogRef<any, any>;
  mimoToleranciModalDialog!: DialogRef<any, any>;

  bramborySource!: TableDataSource<Brambory>;
  seznamExpediceSource!: TableDataSource<Expedice>;
  bramboryItems: Brambory[] = [];
  tempBrambory:Brambory = new Brambory();
  batchesRows!:Batch[];
  mnozstviEnabled!: boolean;
  employeeID!: number;
  smenaID!: number;
  docEntry!: number;
  seznamProVydej: VydejNaSmenu[] = [];
  seznamProVydejMimoToleranci: VydejNaSmenu[] = [];
  mimoToleranci!: string;
  seznamExpedice: Expedice[] = [];
  seznamVyroba: Vyroba[] = [];
  formTypesGroupRegister!: FormGroup;
  public NT = NumpadType;
  public SM = Smena;
  spotrebaVody!: number;
  spotrebaPlynu!: number;
  odpad!: number;
  olej!: number;
  zacatekVyroby!: string;
  konecVyroby!: string;
  dobaPrestavky!: string;
  mnozstvi!: Mnozstvi;
  planovaneMnozstvi!: PlanovaneMnozstvi;
  mnozstviOleje!: Mnozstvi;
  breadcrumbFormatedDate!: string;
  breadCrumbSmena!: string;
  ukonceniStatus!: string;
  bramboryPlanovaneMnozstvi!: Mnozstvi;

  warehouseCode = "01";
  accountCode = "501100";

  vydejka: Vydejka = new Vydejka();

  constructor(
    private dialog: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private navigation: NavigationService,
    private ukonceniSmenyService: UkonceniSmenyService,
    private vydejService: VydejService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    // fake - only for testing purpose
    //this.spotrebaVody = 10;
    //this.spotrebaPlynu = 20;
    //this.odpad = 30;
    //this.zacatekVyroby = "10:00";
    //this.konecVyroby = "20:00";
    //this.dobaPrestavky = "00:30";
    this.validateKonecVyroby();

    this.route.queryParamMap.subscribe((params) => {
      console.log(params);
      this.employeeID = Number(params.get('employeeID'));
      this.smenaID = Number(params.get('smena'));
      this.docEntry = Number(params.get('docEntry'));
      console.log('EmployeeID :', this.employeeID);

      // Kontrola cookie - pokud se lisi, muze to byt zpusobene refreshem stranky, pak ma
      // hodnota uloznea v cookie prednost
      if (this.employeeID !== Number(localStorage.getItem('employeeID')) || this.smenaID !== Number(localStorage.getItem('smena')) || this.docEntry !== Number(localStorage.getItem('docEntry'))) {
        this.employeeID = Number(localStorage.getItem('employeeID'));
        this.smenaID = Number(localStorage.getItem('smena'));
        this.docEntry = Number(localStorage.getItem('docEntry'));
      }

      // Nacteni datumu z cookie
      this.breadcrumbFormatedDate = localStorage.getItem('formatedDate') ?? '';
      let smena = '';
      switch(this.SM[this.smenaID]) {
        case 'R':
          smena = 'ranní';
          break;
        case 'O':
          smena = 'odpolední';
          break;
        case 'N':
          smena = 'noční';
          break;
        default:
          smena = '';
          break;
      }
      this.breadCrumbSmena = smena;

      // Nacteni seznamu pro vydej z cookie
      const seznamProVydej = localStorage.getItem('seznamProVydej');
      this.seznamProVydej = JSON.parse(seznamProVydej ?? '');
      console.log(this.seznamProVydej);

      // Nacteni seznamu expedice z cookie
      const seznamExpedice = localStorage.getItem('seznamExpedice');
      this.seznamExpedice = JSON.parse(seznamExpedice ?? '');
      console.log(this.seznamExpedice);

      // Nacteni seznamu pro vyrobu z cookie
      const seznamVyroba = localStorage.getItem('seznamVyroba');
      this.seznamVyroba = JSON.parse(seznamVyroba ?? '');
      console.log(this.seznamVyroba);

      this.formTypesGroupRegister = new FormGroup({});
      this.mnozstviEnabled = false;
    }
  );

  this.prepareData();
  }

  prepareData() {
    console.log(`Prepare data - planovane mnozstvi a mnozstvi oleje`);

    // mnozstvi oleje
    this.ukonceniSmenyService.getMnozstviOleje(this.SM[this.smenaID]).subscribe({
      next: (olej: any) => {
        olej.forEach((element: any) => {
          console.log(element);
          this.olej = element.BOM_IGNQTY ?? 0;

          // vytvorim vydejku
          const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');

          this.vydejka.DocDate = formatedDate;
          this.vydejka.DocDueDate = formatedDate;
          this.vydejka.U_ABIAG_OIGE = "01";
          this.vydejka.U_ABIACZ_VODA = this.spotrebaVody ?? 0;
          this.vydejka.U_ABIACZ_PLYN = this.spotrebaPlynu ?? 0;
          this.vydejka.U_ABIACZ_ODPAD = this.odpad ?? 0;
          this.vydejka.U_ABIACZ_VSTART = this.zacatekVyroby;
          this.vydejka.U_ABIACZ_VKONEC = this.konecVyroby;
          this.vydejka.U_ABIACZ_VPREST = this.dobaPrestavky;
          this.vydejka.U_ABIACZ_SHIFT = this.SM[this.smenaID];
          //this.vydejka.DocObjectCode = "60";
          this.vydejka.DocumentLines = [];

          // zjistim sarze pro olej a vytvorim document line pro spotrebu oleje, pokud je olej zadany
          if (this.olej !== undefined) {
            console.log(`Mnozstvi zadaneho oleje: ${this.olej}`);
            const oilBatches: OilBatches = new OilBatches();
            this.ukonceniSmenyService.getOilBatches().subscribe({
              next: (val: any) => {
                console.log(val);
                oilBatches.items = [];
                val.value.forEach((row: { DistNumber: string; Quantity: number; }) => {
                  console.log(`Oil batch: ${row.DistNumber}`);

                  const oilBatch: OilBatch = new OilBatch();
                  oilBatch.DistNumber = row.DistNumber;
                  oilBatch.quantity = row.Quantity;

                  oilBatches.items.push(oilBatch);

                });
                console.log(`Oil batches: ${oilBatches}`);

                // vytvorim document line pro spotrebu oleje
                const documentLine: DocumentLine = new DocumentLine();
                documentLine.ItemCode = 'MOL';
                documentLine.Quantity = this.olej;
                documentLine.WarehouseCode = '01';
                documentLine.AccountCode = '501100';
                documentLine.BatchNumbers = [];

                oilBatches.items.forEach((item: OilBatch) => {
                  if (this.olej > 0) {
                    if (this.olej > item.quantity) {
                      const batch: BatchNumber = new BatchNumber();
                      batch.BatchNumber = item.DistNumber;
                      batch.Quantity = item.quantity;

                      documentLine.BatchNumbers.push(batch);

                      this.olej = this.olej - item.quantity;
                    } else {
                      const batch: BatchNumber = new BatchNumber();
                      batch.BatchNumber = item.DistNumber;
                      batch.Quantity = this.olej;

                      documentLine.BatchNumbers.push(batch);

                      this.olej = 0;
                    }
                  }
                });
                console.log(documentLine);
                if (documentLine.Quantity !== undefined && documentLine.Quantity !== null && documentLine.Quantity > 0) {
                  this.vydejka.DocumentLines.push(documentLine);
                }
                localStorage.setItem('vydejka', JSON.stringify(this.vydejka));

                this.seznamProVydej.forEach((item: VydejNaSmenu) => {
                  console.log(item);

                  // exist document line with that item code ?
                  const idx = this.vydejka.DocumentLines.findIndex(elem => elem.ItemCode === item.U_ABIACZ_ITEMCODE);
                  if(idx !== -1) {
                    console.log(`Item Code ${item.U_ABIACZ_ITEMCODE} already exists in the collection! Idx is ${idx} that means document line with item code ${this.vydejka.DocumentLines[idx].ItemCode}`);

                    // calculate quantity for next batch
                    this.vydejka.DocumentLines[idx].Quantity += item.QTY;

                    if (item.U_ABIACZ_BATCH !== null) {
                      const batch: BatchNumber = new BatchNumber();
                      batch.BatchNumber = item.U_ABIACZ_BATCH;
                      batch.Quantity = item.QTY;

                      this.vydejka.DocumentLines[idx].BatchNumbers.push(batch);
                    }
                  } else {
                    const documentLine: DocumentLine = new DocumentLine();
                    documentLine.ItemCode = item.U_ABIACZ_ITEMCODE;
                    documentLine.Quantity = item.QTY;
                    documentLine.WarehouseCode = this.warehouseCode;
                    documentLine.AccountCode = this.accountCode;
                    documentLine.BatchNumbers = [];

                    if (item.U_ABIACZ_BATCH !== null) {
                      const batch: BatchNumber = new BatchNumber();
                      batch.BatchNumber = item.U_ABIACZ_BATCH;
                      batch.Quantity = item.QTY;

                      documentLine.BatchNumbers.push(batch);
                    }

                    if (documentLine.Quantity !== undefined && documentLine.Quantity !== null && documentLine.Quantity > 0) {
                      this.vydejka.DocumentLines.push(documentLine);
                    }
                    localStorage.setItem('vydejka', JSON.stringify(this.vydejka));
                  }

                });

              },
              error: (err: any) => {
                console.error(err);
              }
            });
          }
        });

        console.log(`Mnozstvi oleje: ${this.olej}`);
      },
      error: (err: any) => {
        console.error(err);
      }
    });

    // planovane mnozstvi
    this.ukonceniSmenyService.getPlanovaneMnozstvi(this.SM[this.smenaID]).subscribe({
      next: (items: any) => {
        let mnozstvi: Mnozstvi = new Mnozstvi();
        const planovaneMnozstvi = new PlanovaneMnozstvi();
        planovaneMnozstvi.items = [];
        items.forEach((element: any) => {
          console.log(element);
          mnozstvi.BOM = element.BOM;
          mnozstvi.BOM_IGNQTY = element.BOM_IGNQTY ?? 0;
          mnozstvi.ItemName = element.ItemName;

          // do planovaneMnozstvi dam vsechno krome brambor,
          // brambory si ulozim separatne a dal budu pouzivat k vypoctum
          // podminka: BOM=MB
          if (mnozstvi.BOM === 'MB') {
            this.bramboryPlanovaneMnozstvi = mnozstvi;
          } else {
            planovaneMnozstvi.items.push(mnozstvi);
          }

          mnozstvi = new Mnozstvi();
        });
        this.planovaneMnozstvi = planovaneMnozstvi;
        console.log(`${this.planovaneMnozstvi.items}`);

        // je potreba zparsovat tato kolekce dat se seznamem pro vydej
        this.parsePlanovaneMnozstviASeznamProVydej();
      },
      error: (err: any) => {
        console.error(err);
      }
    });

  }

  parsePlanovaneMnozstviASeznamProVydej() {
    console.log('parsePlanovaneMnozstviASeznamProVydej START');
    // tady zkontroluju pocet brambor v kolekci seznamProVydej, abych se pak mohl rozhodnout pri 
    // vypoctu SCRAPu
    const pocetDruhuBrambor = this.seznamProVydej.filter(x => x.U_ABIACZ_ITEMCODE.substring(0,2) === 'MB');
    const posledniDruh = pocetDruhuBrambor?.reverse()[0];
    console.log(`Pocet druhu brambor: ${pocetDruhuBrambor.length}, posledni z kolekce je ${posledniDruh?.U_ABIACZ_ITEMNAME}`);

    this.seznamProVydej.forEach((vydejNaSmenu: VydejNaSmenu) => {
      console.log(`Vydej na smenu: ${vydejNaSmenu.U_ABIACZ_ITEMCODE}`);
      let x = this.planovaneMnozstvi.items.filter(x => x.BOM === vydejNaSmenu.U_ABIACZ_ITEMCODE);
      // pokud se najde shoda (u brambor se nikdy nenajde, protoze v BOM bude vzdy MB, kdezto v itemCode napr. MBLR)
      if (x.length > 0) {
        vydejNaSmenu.SCRAP = x[0]?.BOM_IGNQTY ?? 0;
          if (vydejNaSmenu.QTY === undefined) {
            vydejNaSmenu.DIFFERENCE = vydejNaSmenu.SCRAP;
            vydejNaSmenu.PercentualDifference = 100;
            vydejNaSmenu.AbsolutePercentualDifference = Math.abs(vydejNaSmenu.PercentualDifference);
          } else {
            vydejNaSmenu.DIFFERENCE = vydejNaSmenu.SCRAP - vydejNaSmenu.QTY;
            vydejNaSmenu.PercentualDifference = (vydejNaSmenu.DIFFERENCE / vydejNaSmenu.QTY) * 100;
            vydejNaSmenu.AbsolutePercentualDifference = Math.abs(vydejNaSmenu.PercentualDifference);
          }
        
        x = [];
      }
      else {
        // pokud jsou x brambory (prvni 2 znaky jsou MB), tak se porovna jejich QTY s bramboryPlanovaneMnozstvi
        // pokud bude QTY < bramboryPlanovaneMnozstvi, pak se normalne zpracuje a provede se
        // bramboryPlanovaneMnozstvi = bramboryPlanovaneMnozstvi - QTY
        //
        // pokud QTY >= bramboryPlanovaneMnozstvi, automaticky se oznaci cervene, 
        // vydejNaSmenu.QTY nastavim na undefined
        // nastavi se bramboryPlanovaneMnozstvi = 0
        //
        // dalsi brambory, pokud nejake budou se automaticky nastavi cervene bez vypoctu, protoze 
        // bramboryPlanovaneMnozstvi bude rovno 0 a
        // vydejNaSmenu.QTY nastavim na undefined
        //
        // if (item.Tolerance === undefined || (item.Tolerance > 0 && item.AbsolutePercentualDifference > item.Tolerance)) {
        //  this.seznamProVydejMimoToleranci.push(item);
        // }

        const first2Chars = vydejNaSmenu.U_ABIACZ_ITEMCODE.substring(0, 2);
        if (first2Chars.toUpperCase() === 'MB') {
          console.log(`Brambory: ${vydejNaSmenu.U_ABIACZ_ITEMNAME}`);
          if (vydejNaSmenu.QTY < this.bramboryPlanovaneMnozstvi.BOM_IGNQTY) {
            console.log(`Brambory: ${vydejNaSmenu.U_ABIACZ_ITEMNAME}, QTY: ${vydejNaSmenu.QTY}, bramboryPlanovaneMnozstvi: ${this.bramboryPlanovaneMnozstvi.BOM_IGNQTY}`);
            if (vydejNaSmenu.QTY === undefined) {
              vydejNaSmenu.DIFFERENCE = vydejNaSmenu.SCRAP;
              vydejNaSmenu.PercentualDifference = 100;
              vydejNaSmenu.AbsolutePercentualDifference = Math.abs(vydejNaSmenu.PercentualDifference);
            } else {
              if (pocetDruhuBrambor.length > 1) {
                // je posledni z kolekce ? pak jej zpracuj jinak
                if (vydejNaSmenu.U_ABIACZ_ITEMCODE == posledniDruh.U_ABIACZ_ITEMCODE) {
                  vydejNaSmenu.SCRAP = this.bramboryPlanovaneMnozstvi.BOM_IGNQTY;
                  vydejNaSmenu.DIFFERENCE = this.bramboryPlanovaneMnozstvi.BOM_IGNQTY - vydejNaSmenu.QTY;
                } else {
                  vydejNaSmenu.SCRAP = vydejNaSmenu.QTY;
                  vydejNaSmenu.DIFFERENCE = 0;
                }
              } else {
                vydejNaSmenu.SCRAP = this.bramboryPlanovaneMnozstvi.BOM_IGNQTY;
                vydejNaSmenu.DIFFERENCE = this.bramboryPlanovaneMnozstvi.BOM_IGNQTY - vydejNaSmenu.QTY;
              }
              vydejNaSmenu.PercentualDifference = (vydejNaSmenu.DIFFERENCE / vydejNaSmenu.QTY) * 100;
              vydejNaSmenu.AbsolutePercentualDifference = Math.abs(vydejNaSmenu.PercentualDifference);
            }
            this.bramboryPlanovaneMnozstvi.BOM_IGNQTY = this.bramboryPlanovaneMnozstvi.BOM_IGNQTY - vydejNaSmenu.QTY;
            console.log(`bramboryPlanovaneMnozstvi: ${this.bramboryPlanovaneMnozstvi.BOM_IGNQTY}`);
          } else {
            vydejNaSmenu.SCRAP = this.bramboryPlanovaneMnozstvi.BOM_IGNQTY;
            vydejNaSmenu.DIFFERENCE = this.bramboryPlanovaneMnozstvi.BOM_IGNQTY - vydejNaSmenu.QTY;
            vydejNaSmenu.PercentualDifference = (vydejNaSmenu.DIFFERENCE / vydejNaSmenu.QTY) * 100;
            vydejNaSmenu.AbsolutePercentualDifference = Math.abs(vydejNaSmenu.PercentualDifference);
            this.bramboryPlanovaneMnozstvi.BOM_IGNQTY = 0;
            console.log(`bramboryPlanovaneMnozstvi: ${this.bramboryPlanovaneMnozstvi.BOM_IGNQTY}`);
          }
        } else {
          vydejNaSmenu.DIFFERENCE = vydejNaSmenu.QTY;
          vydejNaSmenu.PercentualDifference = (vydejNaSmenu.DIFFERENCE / vydejNaSmenu.QTY) * 100;
          vydejNaSmenu.AbsolutePercentualDifference = Math.abs(vydejNaSmenu.PercentualDifference);
        }

        x = [];
      }

    });

    this.planovaneMnozstvi.items.forEach((mnozstvi: Mnozstvi) => {
      console.log(`Planovane mnozstvi: ${mnozstvi.BOM}`);
      let y = this.seznamProVydej.filter(x => x.U_ABIACZ_ITEMCODE === mnozstvi.BOM);
      console.log(`y: ${y}`);
      if (y === null || y === undefined || y.length === 0) {
        const vydejNaSmenu: VydejNaSmenu = new VydejNaSmenu();
        vydejNaSmenu.U_ABIACZ_ITEMCODE = mnozstvi.BOM;
        vydejNaSmenu.SCRAP = mnozstvi.BOM_IGNQTY ?? 0;
        vydejNaSmenu.U_ABIACZ_ITEMNAME = mnozstvi.ItemName;
        vydejNaSmenu.DIFFERENCE = vydejNaSmenu.SCRAP;
        vydejNaSmenu.PercentualDifference = (vydejNaSmenu.DIFFERENCE / vydejNaSmenu.SCRAP) * 100;
        vydejNaSmenu.AbsolutePercentualDifference = Math.abs(vydejNaSmenu.PercentualDifference);
        this.seznamProVydej.push(vydejNaSmenu);

        y = [];
      }
    });
    localStorage.setItem('seznamProVydej', JSON.stringify(this.seznamProVydej));
    console.log('parsePlanovaneMnozstviASeznamProVydej END');

    // U seznamu pro vydej vyhodnotim polozky, ktere presahuji toleranci, pridam si je do pole
    // a zobrazim je v modalnim okne
    // pokud se najde bramboryPlanovaneMnozstvi a ve foreach cyklu se nanajde se zadny item, ktery ma 2 prvni znaky MB (brambory), pak
    // pridame cele bramboryPlanovaneMnozstvi do madolniho okna
    let isMB = false;
    this.seznamProVydej.forEach((item: VydejNaSmenu) => {
      console.log(item);
      const first2Chars = item.U_ABIACZ_ITEMCODE.substring(0, 2);
      if (first2Chars === 'MB') {
        isMB = true;
      }
      if (item.Tolerance === undefined || (item.Tolerance > 0 && item.AbsolutePercentualDifference > item.Tolerance)) {
        this.seznamProVydejMimoToleranci.push(item);
      }
    });


    this.mimoToleranci = this.seznamProVydejMimoToleranci.map(item => item.U_ABIACZ_ITEMNAME).join(', ');
    
    // pridame cele bramboryPlanovaneMnozstvi do madolniho okna
    if (!isMB) {
      this.mimoToleranci += `, ${this.bramboryPlanovaneMnozstvi?.ItemName}`;
    }

    if (this.seznamProVydejMimoToleranci.length > 0) {
      this.mimoToleranciModalDialog = this.dialog.open(this.customTemplate5, {
        responsivePadding: true,
        ariaLabelledBy: 'table-one',
        focusTrapped: true
      });

      this.mimoToleranciModalDialog.afterClosed.subscribe(
        (result: string) => {
          console.log(result);
        },
        (error: string) => {
          console.error(error);
      });
    }
  }

  setSpotrebaVody(spotrebaVody: string) {
    this.spotrebaVody = Number(spotrebaVody);
    console.log(`Spotreba vody: ${this.spotrebaVody}`);
    this.validateKonecVyroby();
  }

  setSpotrebaPlynu(spotrebaPlynu: string) {
    this.spotrebaPlynu = Number(spotrebaPlynu);
    console.log(`Spotreba plynu: ${this.spotrebaPlynu}`);
    this.validateKonecVyroby();
  }

  setOdpad(odpad: string) {
    this.odpad = Number(odpad);
    console.log(`Odpad: ${this.odpad}`);
    this.validateKonecVyroby();
  }

  setOlej(olej: string) {
    this.olej = Number(olej);
    console.log(`Olej: ${this.olej}`);
    this.validateKonecVyroby();
  }

  setZacatekVyroby(zacatekVyroby: string) {
    this.zacatekVyroby = zacatekVyroby;
    console.log(`Zacatek vyroby: ${this.zacatekVyroby}`);
    this.validateKonecVyroby();
  }

  setKonecVyroby(konecVyroby: string) {
    this.konecVyroby = konecVyroby;
    console.log(`Konec vyroby: ${this.konecVyroby}`);
    this.validateKonecVyroby();
  }

  setDobaPrestavky(dobaPrestavky: string) {
    this.dobaPrestavky = dobaPrestavky;
    console.log(`Doba prestavky: ${this.dobaPrestavky}`);
    this.validateKonecVyroby();
  }

  addZacatekVyroby() {
    const input = document.getElementById('zacatek-vyroby') as HTMLInputElement | null;
    this.zacatekVyroby = input?.value ?? '';
    console.log(`Zacatek vyroby: ${this.zacatekVyroby}`);
  }

  addKonecVyroby() {
    const input = document.getElementById('konec-vyroby') as HTMLInputElement | null;
    this.konecVyroby = input?.value ?? '';
    console.log(`Konec vyroby: ${this.konecVyroby}`);
  }

  addDobaPrestavky() {
    const input = document.getElementById('doba-prestavky') as HTMLInputElement | null;
    this.dobaPrestavky = input?.value ?? '';
    console.log(`Doba prestavky: ${this.dobaPrestavky}`);
  }

  validateKonecVyroby() {
    if (
              this.spotrebaVody !== undefined &&
              this.spotrebaPlynu !== undefined &&
              this.zacatekVyroby !== undefined &&
              this.konecVyroby !== undefined
              //&& this.dobaPrestavky !== undefined
        ) {
              const input = document.getElementById('konecSmeny') as HTMLInputElement;
              input.disabled = false;
        }

  }

  brambory() {
    console.log('Brambory');

    this.ukonceniSmenyService.seznamBrambor().subscribe({
      next: (val: any) => {
        console.log(val);
        this.bramboryItems = val;
        this.bramborySource = new TableDataSource(new TableDataProviderBrambory(this.bramboryItems));
      },
      error: (err: any) => {
        console.error(err);
      }
    });

    this.bramboryModalDialog = this.dialog.open(this.customTemplate1, {
      responsivePadding: true,
      ariaLabelledBy: 'table-one',
      focusTrapped: true
    });

    this.bramboryModalDialog.afterClosed.subscribe(
      (result: string) => {
        console.log(result);
      },
      (error: string) => {
        console.error(error);
    });

  }

  konecSmeny() {
    console.log('Konec smeny');

    const input = document.getElementById('konecSmeny') as HTMLInputElement;
    input.disabled = true;

    // Get seznam pro vydej from cookie
    this.seznamProVydej = JSON.parse(localStorage.getItem('seznamProVydej') ?? '');

    if (this.seznamProVydej.length > 0) {
      /*
      const currentDate = new Date();

      if (currentDate.getHours() < 3 ) {
        currentDate.setDate(currentDate.getDate()-1);
      }

      const formatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
      */

      // doplneni atributu az po stisku tlacitka
      this.vydejka.U_ABIACZ_VODA = Math.round(this.spotrebaVody ?? 0);
      this.vydejka.U_ABIACZ_PLYN = Math.round(this.spotrebaPlynu ?? 0);
      this.vydejka.U_ABIACZ_ODPAD = this.odpad ?? 0;
      this.vydejka.U_ABIACZ_VSTART = this.zacatekVyroby;
      this.vydejka.U_ABIACZ_VKONEC = this.konecVyroby;
      this.vydejka.U_ABIACZ_VPREST = this.dobaPrestavky;

      this.ukonceniSmenyService.vydejka(this.vydejka).subscribe ({
        next: (val: any) => {
          console.log(val);
          this.ukonceniSmenyService.seznamDokladuKUzavreni(this.SM[this.smenaID]).subscribe ({
            next: (val: Array<any>) => {
              console.log(val);
              val.forEach((item: any) => {
                console.log(`DocEntry: ${item.docEntry}`);
                // PATCH 15. Uzavreni dokladu
                this.ukonceniSmenyService.uzavreniDokladu(item.docEntry).subscribe({
                  next: (val: any) => {
                    this.ukonceniStatus = 'Ukončení směny proběhlo úspěšně.';
                    const dialogRef = this.dialog.open(this.customTemplate4, {
                      responsivePadding: true,
                      ariaLabelledBy: 'table-one',
                      focusTrapped: true
                    });

                    dialogRef.afterClosed.subscribe(
                      (result: string) => {
                        console.log(result);
                        this.authenticationService.logout();
                      },
                      (error: string) => {
                        console.error(error);
                    });
                  },
                  error: (err: any) => {
                    console.error(err);
                  }
                });
              });
            },
            error: (err: any) => {
              console.error(err);

              this.ukonceniStatus = err;
              const dialogRef = this.dialog.open(this.customTemplate4, {
                responsivePadding: true,
                ariaLabelledBy: 'table-one',
                focusTrapped: true
              });

              dialogRef.afterClosed.subscribe(
                (result: string) => {
                  console.log(result);
                },
                (error: string) => {
                  console.error(error);
              });
            }
          })
        },
        error: (err: any) => {
          console.error(err.error.error.message.value);
          this.ukonceniStatus = err.error.error.message.value;
          const dialogRef = this.dialog.open(this.customTemplate4, {
            responsivePadding: true,
            ariaLabelledBy: 'table-one',
            focusTrapped: true
          });

          dialogRef.afterClosed.subscribe(
            (result: string) => {
              console.log(result);
            },
            (error: string) => {
              console.error(error);
          });
        }
      });
    } else {
      const err = 'Doklad bez řádku nelze vytvořit!';
      console.error(err);
      this.ukonceniStatus = err;
      const dialogRef = this.dialog.open(this.customTemplate4, {
        responsivePadding: true,
        ariaLabelledBy: 'table-one',
        focusTrapped: true
      });

      dialogRef.afterClosed.subscribe(
        (result: string) => {
          console.log(result);
        },
        (error: string) => {
          console.error(error);
      });
    }

  }

  seznamExpediceModal() {
    console.log('Smena expedice modal');

    const dialogRef = this.dialog.open(this.customTemplate3, {
      responsivePadding: true,
      ariaLabelledBy: 'table-one',
      focusTrapped: true
    });

    dialogRef.afterClosed.subscribe(
      (result: string) => {
        console.log(result);
      },
      (error: string) => {
        console.error(error);
    });
  }

  onRowActivate(event: TableRowActivateEvent<any>): void {
    console.log(event);

    if (event.row.itemCode !== undefined) {
      this.ukonceniSmenyService.getBatches(event.row.itemCode).subscribe({
        next: (val: any) => {
          console.log(val.value);
          const batches: Batches = new Batches();
          batches.items = [];
          val.value.forEach((row: { BatchNum: string; Quantity: string; }) => {
            const batch: Batch = new Batch();
            batch.batchNum = row.BatchNum;
            batch.quantity = Number(row.Quantity);
            batch.active = false;

            batches.items.push(batch);
          });

          this.batchesRows = batches.items;

          this.itemsInCategory(event.row.parentCode, event.row.itemName, event.row.itemCode, event.row.manageBatchNumbers, event.row.quantityOnStock);
        },
        error: (err: any) => {
          console.error(err);
        }
      });
    }
  }

  itemsInCategory(categoryCode: string, itemName: string, itemCode: string, manageBatchNumbers: string, quantityOnStock: number) {
    // cleaning temporary vydej
    this.tempBrambory = new Brambory();
    this.tempBrambory.itemName = itemName;
    this.tempBrambory.itemCode = itemCode;
    this.tempBrambory.manageBatchNumbers = manageBatchNumbers;
    this.tempBrambory.quantityOnStock = quantityOnStock;

    const dialogRef = this.dialog.open(this.customTemplate2, {
      responsivePadding: true,
      ariaLabelledBy: 'table-one',
      focusTrapped: true
    });

    dialogRef.afterClosed.subscribe(
      (result: string) => {
        console.log(result);

        // Kontrola existence dokladu - ověřit jestli existuje doklad na uživatele a směnu
        this.vydejService.kontrolaExistenceDokladu(this.employeeID, this.SM[this.smenaID]).subscribe({
          next: (exists: boolean) => {
            console.log('Kontrola excistence dokladu :', exists);

            if (exists) {
              this.vydejService.pridaniRadky2(Number(this.docEntry), this.tempBrambory).subscribe({
                next: (value: any) => {
                  // pridani brambor do vydejky do DocumentLine
                  const documentLine: DocumentLine = new DocumentLine();
                  documentLine.ItemCode = this.tempBrambory.itemCode;
                  documentLine.Quantity = Number(this.tempBrambory.mnozstvi);
                  documentLine.WarehouseCode = '01';
                  documentLine.AccountCode = '501100';
                  documentLine.BatchNumbers = [];

                  const batch: BatchNumber = new BatchNumber();
                  batch.BatchNumber = this.tempBrambory.batchNum;
                  batch.Quantity = this.tempBrambory.quantity;

                  documentLine.BatchNumbers.push(batch);

                  console.log(documentLine);

                  if (documentLine.Quantity !== undefined && documentLine.Quantity !== null && documentLine.Quantity > 0) {
                    this.vydejka.DocumentLines.push(documentLine);
                  }

                  localStorage.setItem('vydejka', JSON.stringify(this.vydejka));

                  // po uspesnem ulozeni refresh dat z cookie
                  //this.seznamProVydej = JSON.parse(localStorage.getItem('seznamProVydej') ?? '');

                  // po uspesnem ulozeni refresh dat
                  this.nacteniDokladu();
                  this.prepareData();
                  //this.parsePlanovaneMnozstviASeznamProVydej();

                  // zavru soucasne i modal pro vyber brambor
                  this.bramboryModalDialog.close();
                },
                error: (err: any) => {
                  console.error(err);
                }
              })
            } else {
              this.vydejService.zalozeniDokladu2(this.employeeID, this.SM[this.smenaID], this.tempBrambory).subscribe({
                next: (value: any) => {
                  console.log(value);
                  // po uspesnem ulozeni refresh dat
                  this.nacteniDokladu();
                },
                error: (err: any) => {
                  console.error(err);
                }
              })
            }
          },
          error: (err: any) => {
            console.error(err);
          }
        });

        this.mnozstviEnabled = false;
      },
      (error: string) => {
        console.error(error);
        this.mnozstviEnabled = false;
    });
  }

  setActiveBatchItem(row: any): void {
    // validace: pokud neni zakliknuta sarze, tak neumoznime vstoupit do kompoonenty mnozstvi a zadat mnozstvi
    this.mnozstviEnabled = true;

    this.batchesRows.forEach(element => {
      if (row.batchNum === element.batchNum) {
        element.active = true;
        this.tempBrambory.batchNum = element.batchNum;
        this.tempBrambory.quantity = Number(element.quantity);
      }
      else {
        element.active = false;
      }
    });
    this.validate();
  }

  setMnozstvi(mnozstvi: string) {
    this.tempBrambory.mnozstvi = mnozstvi;
    this.validate();
  }

  setVadneKusy(vadneKusy: string) {
    console.log(vadneKusy);
  }

  setSpotrebaOd(spotrebaOd: string) {
    this.tempBrambory.spotrebaOd = spotrebaOd;
    if (this.tempBrambory.manageBatchNumbers === 'tYES') {
      this.validate();
    }
  }

  setSpotrebaDo(spotrebaDo: string) {
    this.tempBrambory.spotrebaDo = spotrebaDo;
    if (this.tempBrambory.manageBatchNumbers === 'tYES') {
      this.validate();
    }
  }

  validate() {
    if (this.tempBrambory.manageBatchNumbers === 'tYES') {
      if(this.batchesRows.length > 0) {
        if (this.tempBrambory.itemName !== undefined &&
            this.tempBrambory.mnozstvi !== undefined &&
            this.tempBrambory.spotrebaOd !== undefined &&
            this.tempBrambory.spotrebaDo !== undefined &&
            this.tempBrambory.batchNum !== undefined &&
            this.tempBrambory.quantity !== undefined) {
              const input = document.getElementById('add') as HTMLInputElement;
              input.disabled = false;
          }
      } else {
        if (this.tempBrambory.itemName !== undefined &&
            this.tempBrambory.mnozstvi !== undefined &&
            this.tempBrambory.spotrebaOd !== undefined &&
            this.tempBrambory.spotrebaDo !== undefined) {
              const input = document.getElementById('add') as HTMLInputElement;
              input.disabled = false;
          }
      }
    } else {
      if(this.batchesRows.length > 0) {
        if (this.tempBrambory.itemName !== undefined &&
            this.tempBrambory.mnozstvi !== undefined &&
            this.tempBrambory.batchNum !== undefined &&
            this.tempBrambory.quantity !== undefined) {
              const input = document.getElementById('add') as HTMLInputElement;
              input.disabled = false;
          }
      } else {
        if (this.tempBrambory.itemName !== undefined &&
            this.tempBrambory.mnozstvi !== undefined) {
              const input = document.getElementById('add') as HTMLInputElement;
              input.disabled = false;
          }
      }
    }
  }

  nacteniDokladu() {
    // promazani zaznamu tabulky
     this.seznamProVydej = [];

     this.ukonceniSmenyService.seznamProVydej(this.SM[this.smenaID]).subscribe({
      next: (seznamVydejuNaSmenu: any) => {
        this.seznamProVydej = seznamVydejuNaSmenu;
        localStorage.setItem('docEntry', JSON.stringify(this.docEntry));
        localStorage.setItem('seznamProVydej', JSON.stringify(seznamVydejuNaSmenu));
      },
      error: (err: any) => {
        console.error(err);
      },
      complete() {
        // Will not be called, even when cancelling subscription.
        console.log('completed!');
      }
    });
  }

  back(): void {
    this.navigation.back();
  }

  end() {
    console.log('Konec');
    this.navigation.konec();
  }

}

export class TableDataProviderBrambory extends TableDataProvider<Brambory> {
  override items: Brambory[] = [];
  override totalItems = this.items.length;
  ITEMS: Brambory[] = [];

  constructor(bramboryItems: Brambory[]) {
    super();
    this.ITEMS = bramboryItems;
    this.items = [...bramboryItems];
  }

  override fetch(tableState?: TableState): Observable<Brambory[]> {
    this.items = this.items = [...this.ITEMS];

    // apply searching
    if (tableState?.searchInput) {
        this.items = this.search(this.items, tableState);
    }

    this.totalItems = this.items.length;

    return of(this.items);
}

override search(items: Brambory[], { searchInput, columnKeys }: TableState): Brambory[] {
    const searchText = searchInput?.text || '';
    const keysToSearchBy = columnKeys;

    if (searchText.trim() === '' || keysToSearchBy.length === 0) {
        return items;
    }

    return items.filter((item) => {
        const valuesForSearch = keysToSearchBy.map((key) => getNestedValue(key, item));
        return valuesForSearch
            .filter((value) => !!value)
            .map((value): string => value.toString())
            .some((value) => value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
    });
  }

}

function getNestedValue<T extends Record<string, any>>(key: string, object: T): any {
  return key.split('.').reduce((a, b) => (a ? a[b] : null), object);
}
