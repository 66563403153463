export class Brambory {
    itemName!: string;
    itemCode!: string;
    manageBatchNumbers!: string;
    quantityOnStock!: number;
    batchNum!: string;
    quantity!: number;
    mnozstvi!: string;
    spotrebaOd!: string;
    spotrebaDo!: string;
}