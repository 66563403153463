<div id="menu-smena">
    <fd-segmented-button >
        <button fd-button value={{yesterdays}} (click)="setFormatedDate(-1)">{{yesterdays}}</button>
        <button fd-button value={{todays}} (click)="setFormatedDate(0)">{{todays}}</button>
        <button fd-button value={{tomorrows}} (click)="setFormatedDate(1)">{{tomorrows}}</button>
    </fd-segmented-button>

    <button (click)="nextAction(SM.R)">
        Ranní směna
    </button>
    
    <button (click)="nextAction(SM.O)">
        Odpolední směna
    </button>  
    
    <button (click)="nextAction(SM.N)">
        Noční směna
    </button> 
</div>

<div class="ukonceni-modal">
    <ng-template let-dialog let-dialogConfig="dialogConfig" #confirmationDialog1>
        <fd-dialog [dialogConfig]="dialogConfig" [dialogRef]="dialog">
            <fd-dialog-body>
                {{status}}
            </fd-dialog-body>
            <fd-dialog-footer>
                <fd-button-bar label="Ok" (click)="dialog.close('Ok')" ariaLabel="Ok"></fd-button-bar>
            </fd-dialog-footer>
        </fd-dialog>   
    </ng-template>
</div>

<div class="footer">
    <input type="button" value="Zpět" (click)="back()" aria-label="Back" id="back" class="back"/>
    <input type="button" value="Konec" (click)="end()" ariaLabel="End" id="end" class="end" />
</div>