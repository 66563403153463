export class EmployeesInfo {
    employeeID!: number;
    firstName!: string;
    lastName!: string;
    salesPersonCode!: number;
    employeeRolesInfoLines!: EmployeeRolesInfoLines;
}

export class EmployeeRolesInfoLines {
    roleID!: number;
}