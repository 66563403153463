import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './components/signin/signin.component';
import { FunkceComponent } from './components/funkce/funkce.component';
import { AuthGuard } from './guards/auth.guard';
import { SmenaComponent } from './components/smena/smena.component';
import { VydejComponent } from './components/vydej/vydej.component';
import { UkonceniSmenyComponent } from './components/ukonceni-smeny/ukonceni-smeny.component';
import { VyrobkySklademComponent } from './components/vyrobky-skladem/vyrobky-skladem.component';
import { EditaceSmenyComponent } from './components/editace-smeny/editace-smeny.component';

const routes: Routes = [
  { path: '', component: SigninComponent, canActivate: [AuthGuard] },
  { path: 'signin', component: SigninComponent },
  { path: 'funkce', component: FunkceComponent, canActivate: [AuthGuard] },
  { path: 'smena', component: SmenaComponent, canActivate: [AuthGuard] },
  { path: 'vydej', component: VydejComponent, canActivate: [AuthGuard] },
  { path: 'konec', component: UkonceniSmenyComponent, canActivate: [AuthGuard] },
  { path: 'vyrobky-skladem', component: VyrobkySklademComponent, canActivate: [AuthGuard] },
  { path: 'ukonceni-smeny', component: UkonceniSmenyComponent, canActivate: [AuthGuard]},
  { path: 'editace-smeny', component: EditaceSmenyComponent, canActivate: [AuthGuard]},

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
