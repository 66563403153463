import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { map } from 'rxjs';
import { formatDate } from '@angular/common';
import { Smena } from '../models/smena';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  actionUrl: string;
  
  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
  ) { 
    this.actionUrl = appSettings.config.actionUrl;
  }

  ukonceniSmeny(smena: string) {
    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');
    const url = this.actionUrl + `/InventoryGenExits/$count?$filter=U_ABIAG_OIGE eq '01' and DocumentStatus eq 'bost_Open' and DocDate eq '${formatedDate}' and U_ABIACZ_SHIFT eq '${smena}'`;
        
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }

    return this.http.get<number>(url, options)
    .pipe(map(validNumber => {

      return validNumber;
    }))
  }
}
