import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { AuthenticationService } from './authentication.service';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { VydejNaSmenu } from '../models/vydejNaSmenu';
import { formatDate } from '@angular/common';
import { Brambory } from '../models/brambory';
import { Batch } from '../models/batches';
import { Vydejka } from '../models/vydejka';
import { DokladKUzavreni } from '../models/dokladKUzavreni';
import { Expedice } from '../models/expedice';
import { Vyroba } from '../models/vyroba';

@Injectable({
  providedIn: 'root'
})
export class UkonceniSmenyService {

  actionUrl: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
    private authenticationService: AuthenticationService
  ) {
    this.actionUrl = appSettings.config.actionUrl;
  }

  seznamProVydej(smena: string): Observable<Array<any>> {
    const seznamVydejuNaSmenu: Array<any> = [];
    /*
    const currentDate = new Date();
    const formatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    */

    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');
    const url = this.actionUrl + `/SQLQueries('sql02_tst')/List?Shift='${smena}'&Date='${formatedDate}'`;
    //const url = "https://srv-hana01-nodea.abiacz.com:50000/b1s/v1/SQLQueries('sql02')/List?Shift='R'&Date='2023-07-12'";
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      items.value.forEach((element: { QTY: number; SCRAP: number; U_ABIACZ_BATCH: string; U_ABIACZ_ITEMCODE: string; U_ABIACZ_ITEMNAME: string; Tolerance: number;}) => {
        const vydejNaSmenu: VydejNaSmenu = new VydejNaSmenu();
        vydejNaSmenu.QTY = element.QTY;
        vydejNaSmenu.SCRAP = element.SCRAP;
        vydejNaSmenu.U_ABIACZ_BATCH = element.U_ABIACZ_BATCH;
        vydejNaSmenu.U_ABIACZ_ITEMCODE = element.U_ABIACZ_ITEMCODE;
        vydejNaSmenu.U_ABIACZ_ITEMNAME = element.U_ABIACZ_ITEMNAME;
        vydejNaSmenu.Tolerance = element.Tolerance;
        seznamVydejuNaSmenu.push(vydejNaSmenu);
      });

      return seznamVydejuNaSmenu;
    }));
  }

  seznamBrambor() {
    const seznamBrambor: Array<any> = [];
    const url = this.actionUrl + `/Items?$filter=U_ABIACZ_SURKAT eq 'MB' and QuantityOnStock gt 0&$select=ItemCode,ItemName,ManageBatchNumbers,QuantityOnStock`;
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      items.value.forEach((element: { ItemCode: string; ItemName: string; QuantityOnStock: number; ManageBatchNumbers: string;}) => {
        const brambory: Brambory = new Brambory();
        brambory.itemCode = element.ItemCode;
        brambory.itemName = element.ItemName;
        brambory.quantityOnStock = element.QuantityOnStock;
        brambory.manageBatchNumbers = element.ManageBatchNumbers;
        seznamBrambor.push(brambory);
      });

      return seznamBrambor;
    }));
  }

  getBatches(itemCode: string) {
    const url = this.actionUrl + `/SQLQueries('sql01')/List?ItemCode='${itemCode}'`;
    console.log(`Item code is ${itemCode}`);
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }

    return this.http.get<Batch[]>(url, options)
    .pipe(map(bachItems => {

      return bachItems;
    }))
  }

  vydejka(vydejka: Vydejka) {
    console.log(JSON.stringify(vydejka));

    const url = this.actionUrl + `/InventoryGenExits`;
    //const url = this.actionUrl + `/Drafts`;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      withCredentials: true
    }
    const body = JSON.stringify(vydejka);

    return this.http.post<any>(url, body, options)
    .pipe(
      map((response: any) => {
        console.log(response);
        return response;
      }),
      //catchError(this.errorHandler)
      catchError((error) => {
        return throwError(error);
     })
    ); 
  }
  
  seznamDokladuKUzavreni(smena: string): Observable<Array<any>> {
    const seznamDokladuKUzavreni: DokladKUzavreni[] = [];
    /*
    const currentDate = new Date();
    const formatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    */

    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');
    const url = this.actionUrl + `/ABIACZ_OSDV?$filter=U_ABIACZ_DOCDATE eq ${formatedDate} and U_ABIACZ_SHIFT eq '${smena}' and U_ABIACZ_Status eq 'O'&$select=DocEntry`;

    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
      }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      items.value.forEach((element: { DocEntry: number; }) => {
        const dokladKUzavreni: DokladKUzavreni = new DokladKUzavreni();
        dokladKUzavreni.docEntry = element.DocEntry;
        seznamDokladuKUzavreni.push(dokladKUzavreni);
      });

      return seznamDokladuKUzavreni;
    }));
  }

  uzavreniDokladu(docEntry: string): Observable<any> {
    const url = this.actionUrl + `/ABIACZ_OSDV(${docEntry})`;
    const options = {
      // headers: new HttpHeaders({
      //   'B1S-ReplaceCollectionsOnPatch': 'true'
      //  }),
      withCredentials: true
    }

    const body = `{
      "U_ABIACZ_Status": "C"
    }`;

    return this.http.patch<any>(url, body, options)
    .pipe(map(result => {

      return result;
    }))

    // return this.http.patch<any>(url, body, options)
    //       .subscribe(
    //         res => {
    //           console.log('HTTP response', res);
    //           // localStorage.setItem('doklad', JSON.stringify(vydej));
    //           // location.reload();
    //           this.authenticationService.logout();
    //         },
    //         err => console.log('HTTP Error', err),
    //         () => console.log('HTTP request completed.')

    //       );
  }

  seznamExpedice(smena: string): Observable<Array<any>> {
    const seznamExpedice: Array<any> = [];
    /*
    const currentDate = new Date();
    const formatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    */

    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');
    const url = this.actionUrl + `/SQLQueries('sql03')/List?Date='${formatedDate}'&Shift='${smena}'`;
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
      }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      items.value.forEach((element: { Expedice: string; Dscription: string; Quantity: number; InvQty: number;}) => {
        const expedice: Expedice = new Expedice();
        expedice.Expedice = element.Expedice;
        expedice.Dscription = element.Dscription;
        expedice.Quantity = element.Quantity;
        expedice.InvQty = element.InvQty;
        seznamExpedice.push(expedice);
      });

      return seznamExpedice;
    }));
  }

  seznamVyroba(smena: string): Observable<Array<any>> {
    const seznamVyroba: Array<any> = [];
    /*
    const currentDate = new Date();
    const formatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    */

    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');
    const url = this.actionUrl + `/SQLQueries('sql06')/List?Date='${formatedDate}'&Shift='${smena}'`;
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
      }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      items.value.forEach((element: { Dscription: string; Quantity: number; InvQty: number}) => {
        const vyroba: Vyroba = new Vyroba();
        vyroba.Dscription = element.Dscription;
        vyroba.Quantity = element.Quantity;
        vyroba.InvQty = element.InvQty;
        seznamVyroba.push(vyroba);
      });

      return seznamVyroba;
    }));
  }

  getOilBatches(): Observable<Array<any>> {
    let oilBatches: Array<any> = [];
    const url = this.actionUrl + `/SQLQueries('sql04')/List`;

    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
      }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      oilBatches = items;

      return oilBatches;
    }));
  }

  getMnozstviOleje(smena: string) {
    let mnozstviOleje: any;
    /*
    const currentDate = new Date();
    const formatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    */

    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');
    const url = this.actionUrl + `/sml.svc/BOMCONSUMED_MOL?$select=BOM,BOM_IGNQTY&$filter=DocDate eq '${formatedDate}' and U_ABIACZ_SHIFT eq '${smena}'`;

    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
      }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(item => {
      mnozstviOleje = item.value;
      return mnozstviOleje;
    }));
  }

  getPlanovaneMnozstvi(smena: string) {
    let planovaneMnozstvi: Array<any> = [];
    /*
    const currentDate = new Date();
    const formatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    */

    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');
    const url = this.actionUrl + `/sml.svc/BOMCONSUMED?$select=BOM,BOM_IGNQTY,ItemName&$filter=DocDate eq '${formatedDate}' and U_ABIACZ_SHIFT eq '${smena}'`;

    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
      }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      planovaneMnozstvi = items.value;

      return planovaneMnozstvi;
    }));
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Nastala neočekávaná chyba. Prosím opakujte akci znovu.');
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
