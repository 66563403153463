export class VydejNaSmenu {
    QTY!: number;
    SCRAP!: number;
    U_ABIACZ_BATCH!: string;
    U_ABIACZ_ITEMCODE!: string;
    U_ABIACZ_ITEMNAME!: string;
    DIFFERENCE!: number;
    PercentualDifference!: number;
    AbsolutePercentualDifference!: number;
    Tolerance!: number;
}