import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EditaceSmeny } from '../models/editaceSmeny';
import { AppSettingsService } from './app-settings.service';
import { ABIACZ_SDV1Collection } from '../models/doklad';

@Injectable({
  providedIn: 'root'
})
export class EditaceSmenyService {

  actionUrl!: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService
  ) { 
    this.actionUrl = appSettings.config.actionUrl;
  }

  editaceSmeny(smena: string): Observable<Array<EditaceSmeny>> {
    const seznamEditaceSmeny: Array<EditaceSmeny> = [];
    /*
    const currentDate = new Date();
    const formatedDate = formatDate(currentDate, 'YYYY-MM-dd', 'en');
    */

    const formatedDate = localStorage.getItem('formatedDate') ?? formatDate(new Date, 'YYYY-MM-dd', 'en');
    const url = this.actionUrl + `/SQLQueries('sql09')/List?Shift='${smena}'&Date='${formatedDate}'`;
    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }
    return this.http.get<any>(url, options)
    .pipe(map(items => {
      items.value.forEach((element: { DocEntry: number; LineId: number; U_ABIACZ_BATCH: string; U_ABIACZ_DATE: number; U_ABIACZ_FROM: number; U_ABIACZ_ITEMNAME: string; U_ABIACZ_QTY: number; U_ABIACZ_SCRAP: number; U_ABIACZ_TIME: number; U_ABIACZ_TO: number; firstName: string; lastName: string; }) => {
        const editaceSmeny: EditaceSmeny = new EditaceSmeny();
        editaceSmeny.DocEntry = element.DocEntry;
        editaceSmeny.LineId = element.LineId;
        editaceSmeny.U_ABIACZ_BATCH = element.U_ABIACZ_BATCH;
        editaceSmeny.U_ABIACZ_DATE = element.U_ABIACZ_DATE;
        editaceSmeny.U_ABIACZ_FROM = element.U_ABIACZ_FROM;
        editaceSmeny.U_ABIACZ_ITEMNAME = element.U_ABIACZ_ITEMNAME;
        editaceSmeny.U_ABIACZ_QTY = element.U_ABIACZ_QTY;
        editaceSmeny.U_ABIACZ_SCRAP = element.U_ABIACZ_SCRAP;
        editaceSmeny.U_ABIACZ_TIME = element.U_ABIACZ_TIME;
        editaceSmeny.U_ABIACZ_TO = element.U_ABIACZ_TO;
        editaceSmeny.firstName = element.firstName;
        editaceSmeny.lastName = element.lastName;
        seznamEditaceSmeny.push(editaceSmeny);
      });

      return seznamEditaceSmeny;
    }));
  }

  nacteniDokladu(docEntry: number): Observable<Array<ABIACZ_SDV1Collection>> {
    const sDV1CollectionList: Array<ABIACZ_SDV1Collection> = [];
    const url = this.actionUrl + `/ABIACZ_OSDV(${docEntry})?$select=ABIACZ_SDV1Collection`;
    console.log('URL :', url);

    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }

    return this.http.get<any>(url, options)
    .pipe(map(items => {
      items.ABIACZ_SDV1Collection.forEach((element: { DocEntry: number; LineId: number; VisOrder: number; Object: string; U_ABIACZ_ITEMCODE: string; U_ABIACZ_ITEMNAME: string; U_ABIACZ_QTY: number; U_ABIACZ_BATCH: string; U_ABIACZ_DATE: string; U_ABIACZ_TIME: string; U_ABIACZ_SCRAP: number;  U_ABIACZ_FROM: string; U_ABIACZ_TO: string; }) => {
        const sDV1Collection: ABIACZ_SDV1Collection = new ABIACZ_SDV1Collection();
        sDV1Collection.DocEntry = element.DocEntry;
        sDV1Collection.LineId = element.LineId;
        sDV1Collection.VisOrder = element.VisOrder;
        sDV1Collection.Object = element.Object;
        sDV1Collection.U_ABIACZ_ITEMCODE = element.U_ABIACZ_ITEMCODE;
        sDV1Collection.U_ABIACZ_ITEMNAME = element.U_ABIACZ_ITEMNAME;
        sDV1Collection.U_ABIACZ_QTY = element.U_ABIACZ_QTY;
        sDV1Collection.U_ABIACZ_BATCH = element.U_ABIACZ_BATCH;
        sDV1Collection.U_ABIACZ_DATE = element.U_ABIACZ_DATE;
        sDV1Collection.U_ABIACZ_TIME = element.U_ABIACZ_TIME;
        sDV1Collection.U_ABIACZ_SCRAP = element.U_ABIACZ_SCRAP;
        sDV1Collection.U_ABIACZ_FROM = element.U_ABIACZ_FROM;
        sDV1Collection.U_ABIACZ_TO = element.U_ABIACZ_TO;

        sDV1CollectionList.push(sDV1Collection);
      });
      return sDV1CollectionList;
    }))
  }

  vymazRadek(docEntry: number, doklad: ABIACZ_SDV1Collection[], editaceSmeny: EditaceSmeny[]) {
    const url = this.actionUrl + `/ABIACZ_OSDV(${docEntry})`;
    console.log('URL :', url);

    const options = {
      headers: new HttpHeaders({
        'B1S-ReplaceCollectionsOnPatch': 'true'
       }),
      withCredentials: true
    }

    const editaceSmenyJSON = JSON.stringify(editaceSmeny);
    const dokladJSON = JSON.stringify(doklad);
    const body = `{
      "ABIACZ_SDV1Collection":
        ${dokladJSON}
    }`;

    return this.http.patch<any>(url, body, options)
      .subscribe(
        res => {
          console.log('HTTP response', res);
          localStorage.setItem('editacePolozkyDokladu', dokladJSON);
          localStorage.setItem('seznamEditaceSmeny', editaceSmenyJSON);
          location.reload();
        },
        err => console.log('HTTP Error', err),
        () => console.log('HTTP request completed.')

      );
  }

}
