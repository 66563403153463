import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FundamentalNgxCoreModule } from '@fundamental-ngx/core';
import { FundamentalNgxPlatformModule } from '@fundamental-ngx/platform';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NumpadComponent } from './components/numpad/numpad.component';
import { SigninComponent } from './components/signin/signin.component';
import { AppSettingsService } from './services/app-settings.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { FunkceComponent } from './components/funkce/funkce.component';
import { ContentDensityService, RtlService } from '@fundamental-ngx/cdk';
import { SmenaComponent } from './components/smena/smena.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { VydejComponent } from './components/vydej/vydej.component';
import { UkonceniSmenyComponent } from './components/ukonceni-smeny/ukonceni-smeny.component';
import { VyrobkySklademComponent } from './components/vyrobky-skladem/vyrobky-skladem.component';
import { EditaceSmenyComponent } from './components/editace-smeny/editace-smeny.component';

@NgModule({
  declarations: [
    AppComponent,
    NumpadComponent,
    SigninComponent,
    FunkceComponent,
    SmenaComponent,
    BackButtonDirective,
    VydejComponent,
    UkonceniSmenyComponent,
    VyrobkySklademComponent,
    EditaceSmenyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FundamentalNgxCoreModule,
    FundamentalNgxPlatformModule,
    HttpClientModule, 
    AppRoutingModule,    
    RouterModule.forRoot([{path: '#', component:FunkceComponent}], { useHash: true }),
  ],
  providers: [
    RtlService, ContentDensityService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appSettingsService: AppSettingsService) =>
        () => appSettingsService.load(),
      deps: [AppSettingsService],
      multi: true,
    },
     { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
