import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { NumpadComponent } from '../numpad/numpad.component';
import { EmployeesInfo } from 'src/app/models/employeesInfo';
import { Router } from '@angular/router';
import { NumpadType } from 'src/app/models/numpadType';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent {
  @ViewChild(NumpadComponent) pin: any;
  employee!: EmployeesInfo;

  public NT = NumpadType;
  
  constructor(
    private router: Router,
    private appSettingsService: AppSettingsService,
    private authenticationService: AuthenticationService,
    private navigation: NavigationService) { }

  back(): void {
    this.navigation.back();
  }
}
