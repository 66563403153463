import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { AppSettingsService } from './app-settings.service';
import { EmployeesInfo } from '../models/employeesInfo';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private userSubject!: BehaviorSubject<User>;
  public user!: Observable<User>;

  ServiceLayer_Login = new Observable<any>();
  actionUrl!: string;
  database!: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private appSettings: AppSettingsService,
  ) {
    this.actionUrl = appSettings.config.actionUrl;
    this.database = appSettings.config.database;

    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user') || '{}'));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  getUser() : Observable<User> {
    return this.userSubject.asObservable();
  }

  login(username: string, password: string) {
    const jData = JSON.stringify({
                CompanyDB: this.database,
                UserName: username,
                Password: password
    });
    const url = this.actionUrl + '/Login';

    const options = {
      withCredentials: true
    }

    return  this.http.post<any>(url, jData, options)
      .pipe(map(user => {
        // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
        user.authdata = window.btoa(username + ':' + password);
        //localStorage.setItem('user', JSON.stringify(user));
        //localStorage.setItem('timeout',(Date.now() + user['SessionTimeout']*60000).toString());
        this.userSubject.next(user);
        return user;
    }));

  }

  checkPin(pin: string): Observable<EmployeesInfo[]> {
    const url = this.actionUrl + `/EmployeesInfo?$filter=OfficeExtension eq '${pin}' &$select=EmployeeID,FirstName,LastName,SalesPersonCode,EmployeeRolesInfoLines`;
    console.log('URL :', url);

    const options = {
      headers: new HttpHeaders({
        'Prefer': 'odata.maxpagesize=200'
       }),
      withCredentials: true
    }

    return this.http.get<EmployeesInfo[]>(url, options)
    .pipe(map(employeeInfo => {
      
      return employeeInfo;
    }))
  }

  logout() {
    console.log('Sign out');
    
    this.flushCache();
    this.userSubject.next(new User());
    this.router.navigate(['/signin']);
  }

  flushCache() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('timeout');
    localStorage.removeItem('employeeID');
    localStorage.removeItem('smena');
    localStorage.removeItem('categoryItems');
    localStorage.removeItem('doklad');
    localStorage.removeItem('docEntry');
    localStorage.removeItem('action');
    localStorage.removeItem('seznamProVydej');
    localStorage.removeItem('seznamExpedice');
    localStorage.removeItem('currentDate');
    localStorage.removeItem('formatedDate');
    localStorage.removeItem('roleID');
    localStorage.removeItem('seznamVyroba');
    localStorage.removeItem('vydejka');
    localStorage.removeItem('skladBrandItems');
    localStorage.removeItem('skladOwnItems');
    localStorage.removeItem('seznamEditaceSmeny');
    localStorage.removeItem('editacePolozkyDokladu');
  }
}
